import { Stack, Typography } from "@mui/material";
import React from "react";

const Label = (props) => {
  return (
    <>
      <Stack sx={{ width: "100%", pb: "0.4rem" }}>
        <Typography typography={"textSmReg"}>{props.name}</Typography>
      </Stack>
    </>
  );
};

export default Label;
