const JobExperience = [
  { value: "0 to 6 months", label: "0 to 6 months" },
  { value: "Less than 1 year", label: "Less than 1 year" },
  { value: "1 year", label: "1 year" },
  { value: "2+ years", label: "2+ years" },
  { value: "5+ years ", label: "5+ years" },
  { value: "10+ years ", label: "10+ years" },
  { value: "15+ years", label: "15+ years" },
  { value: "20 years or more", label: "20 years or more" },
];

const preferredLocations = [
  {
    label: "Chandigarh",
    value: "Chandigarh",
  },
  {
    label: "Mohali",
    value: "Mohali",
  },
  {
    label: "Noida",
    value: "Noida",
  },
  {
    label: "Hybrid",
    value: "Hybrid",
  },
  {
    label: "Remote",
    value: "Remote",
  },
];

export { JobExperience, preferredLocations };
