import { Skeleton, Stack } from "@mui/material";
import React from "react";

function JDSkeleton() {
  return (
    <Stack display={"flex"} justifyContent="center" mx={"auto"} maxWidth={"md"}>
      {/* <Skeleton
        sx={{ my: 2, mt: 4, mx: "auto" }}
        variant="text"
        width={"30%"}
      />
      <Skeleton sx={{ my: 1, mx: "auto" }} variant="text" width={"80%"} />
      <Skeleton variant="text" width={"100%"} />

      <Skeleton sx={{ mb: 4 }} variant="text" width={"100%"} /> */}
      <Stack
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        gap={4}
        flexDirection={"row"}
      >
        <Skeleton variant="rounded" width={"100%"} height={40} />
      </Stack>

      <Skeleton sx={{ mt: 6 }} variant="rounded" width={"100%"} height={500} />
    </Stack>
  );
}

export default JDSkeleton;
