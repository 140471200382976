import { Stack, Button, Typography } from "@mui/material";
import React from "react";
import { Link, useOutletContext } from "react-router-dom";
import parse from "html-react-parser";

const Overview = () => {
  const { setValue, opening } = useOutletContext();

  return (
    <Stack maxWidth={"80%"} my={4}>
      {/* Job Summary */}
      <Stack mb={1}>
        {opening.id !== 17 && (
          <Typography sx={{ typography: "textLgSemi", mb: -1 }}>
            Job Summary
          </Typography>
        )}
        <Typography
          sx={{
            fontFamily: "Inter !important",
            typography: "textMdReg",
            maxWidth: "800px",
          }}
        >
          {parse(opening.job_summary)}
        </Typography>
      </Stack>
      {/* Key Responsibilities */}
      <Stack mb={1}>
        <Typography sx={{ typography: "textLgSemi", mb: -1 }}>
          Key Responsibilities
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter !important",
            typography: "textMdReg",
            maxWidth: "800px",
          }}
        >
          {parse(opening.key_responsibilities)}
        </Typography>
      </Stack>
      {/* Requirements */}
      {opening.requirements !== "" && (
        <Stack mb={1}>
          <Typography sx={{ typography: "textLgSemi", mb: -1 }}>
            Requirements
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter !important",
              typography: "textMdReg",
              maxWidth: "800px",
            }}
          >
            {parse(opening.requirements)}
          </Typography>
        </Stack>
      )}
      {/* Preferred Qualifications */}
      <Stack mb={1}>
        <Typography sx={{ typography: "textLgSemi", mb: -1 }}>
          Preferred Qualifications
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter !important",
            typography: "textMdReg",
            maxWidth: "800px",
          }}
        >
          {parse(opening.preferred_qualifications)}
        </Typography>
      </Stack>

      {parse(opening.note) !== "" && (
        <>
          {/* End Note */}
          <Stack mb={1}>
            {/* <Typography sx={{ typography: "textLgSemi" }}>Note</Typography> */}
            <Typography
              sx={{
                fontFamily: "Inter !important",
                typography: "textMdReg",
                maxWidth: "800px",
              }}
            >
              {parse(opening.note)}
            </Typography>
          </Stack>
        </>
      )}

      <Stack
        direction={{ xs: "column", md: "row" }}
        display={{ xs: "initial", md: "flex" }}
        mt={5}
        alignItems={"center"}
      >
        <Link to="apply-now" style={{ all: "unset", maxWidth: "max-content" }}>
          <Button
            onClick={() => {
              setValue(1);
            }}
            sx={[
              {
                width: "100%",
                mr: { md: 2 },
                color: "white",
                bgcolor: "primary.blue600",
                fontSize: "16px",
                fontWeight: "600",
                padding: "12px 20px",
                lineHeight: "24px",
              },
              {
                "&:hover": {
                  bgcolor: "primary.blue700",
                },
              },
            ]}
          >
            Apply Now
          </Button>
        </Link>
        <Typography
          sx={{
            typography: "textLgReg",
            p: 1,
            textAlign: "center",
            fontStyle: "italic",
          }}
        >
          {" "}
          OR{" "}
        </Typography>
        <Link to="referral" style={{ all: "unset", maxWidth: "max-content" }}>
          <Button
            onClick={() => {
              setValue(2);
            }}
            sx={[
              {
                mx: { md: 1 },
                color: "white",
                bgcolor: "primary.blue600",
                fontSize: "16px",
                width: "100%",
                fontWeight: "600",
                padding: "12px 20px",
                lineHeight: "24px",
              },
              {
                "&:hover": {
                  bgcolor: "primary.blue700",
                },
              },
            ]}
          >
            Refer Someone
          </Button>
        </Link>
      </Stack>
    </Stack>
  );
};

export default Overview;
