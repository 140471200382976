import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { lightTheme } from "./utils/Theme";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import routes from "./routes";

ReactGA.initialize("G-WT1F5P1T27");

function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Careers",
    });
  }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <RouterProvider router={routes} />
    </ThemeProvider>
  );
}

export default App;
