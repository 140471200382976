import React from "react";
import { Stack } from "@mui/system";
import PaperContainer from "../layouts/PaperContainer";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <PaperContainer mt={10} bgColor={"#f6f6f6"}>
      <Stack spacing={2} py={8} maxWidth="xl" mx={"auto"}>
        <Typography
          sx={{
            typography: { xs: "textMdSemi" },
            color: "primary.gray900",
          }}
        >
          Who We Are
        </Typography>
        <Stack
          display={"flex"}
          spacing={2}
          pb={4}
          direction={{ xs: "column", md: "row" }}
          justifyContent={"space-between"}
        >
          <Typography
            sx={{
              color: "primary.gray800",
              typography: { xs: "displayXlBold", md: "displayXlBold" },
            }}
          >
            Learn More About IDS
          </Typography>
          <Typography
            color={"primary.gray700"}
            maxWidth="sm"
            px={{ xs: 0, md: 5 }}
            sx={{ typography: { xs: "textSmLeg", sm: "textMdReg" } }}
          >
            Trusted by Fortune 500 companies for 34+ years for High Service
            Excellence. IDS is built on the legacy of providing customized
            solutions to professionals and business houses across different
            industries, enabling them to optimize their business processes,
            achieve excellence and increase productivity.
          </Typography>
        </Stack>
        <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
          <Link
            to="https://idsil.com/"
            target="_blank"
            style={{ all: "unset" }}
          >
            <Button
              sx={[
                {
                  color: "primary.gray900",
                  // border: "1px solid",
                  // borderColor: "primary.gray200",
                  fontSize: "16px",
                  width: "100%",
                  mb: { xs: 2, md: "initial" },
                  backgroundColor: "#eaecf0",
                  fontWeight: "600",
                  padding: "10px 18px",
                  lineHeight: "20px",
                },
                {
                  "&:hover": {
                    backgroundColor: "primary.blue600",
                    color: "white",
                  },
                },
              ]}
            >
              Corporate
            </Button>
          </Link>
          <Link to="/about-us" style={{ all: "unset" }}>
            <Button
              sx={[
                {
                  width: "100%",
                  color: "white",
                  bgcolor: "primary.blue600",
                  fontSize: "16px",
                  fontWeight: "600",
                  ml: { md: 2 },
                  padding: "10px 18px",
                  lineHeight: "20px",
                },
                {
                  "&:hover": {
                    backgroundColor: "primary.blue700",
                  },
                },
              ]}
            >
              About us
            </Button>
          </Link>
        </Stack>
      </Stack>
    </PaperContainer>
  );
};

export default About;
