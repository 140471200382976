import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

const ClientCard = (props) => {
  return (
    <div>
      <Card elevation={false} sx={{ backgroundColor: "#f6f6f6" }}>
        <CardContent>
          <Typography
            sx={{
              typography: { xs: "displayLgSemi", md: "displayXlSemi" },
              color: "primary.blue700",
            }}
          >
            {props.heading}
          </Typography>
          <Typography
            py={2}
            sx={{ typography: { xs: "textSmSemi", md: "textXlSemi" } }}
          >
            {props.mainHeading}
          </Typography>
          <Typography sx={{ typography: { xs: "textLgReg", md: "textLgReg" } }}>
            {props.subHeading}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default ClientCard;
