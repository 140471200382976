import React from "react";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

const AboutCard = (props) => {
  return (
    <Stack
      maxWidth={{ xs: "initial", sm: "50%", md: "25%", lg: "30%" }}
      spacing={3}
      m={2}
    >
      <Link to={props.link} style={{ all: "unset" }}>
        <Stack
          p={4}
          spacing={3}
          className="caseStudy_Card"
          bgcolor={"#f6f6f6"}
          sx={[
            {
              borderRadius: 3,
              cursor: "pointer",
              "&:hover": {
                boxShadow: "0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
                cursor: "pointer",
                color: "white",
              },
            },
          ]}
        >
          <Typography
            sx={{ typography: { xs: "textLgSemi", md: "textXlSemi" } }}
          >
            {props.title}
          </Typography>
          <Typography>{props.data}</Typography>
        </Stack>
      </Link>
    </Stack>
  );
};

export default AboutCard;
