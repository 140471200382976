import { Typography, useMediaQuery } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Subscriber from "../Subscriber";

const Banner = () => {
  const isMatch = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Stack
      maxWidth={"xl"}
      mx="auto"
      minHeight={"95vh"}
      my={{ xs: 0, md: 2 }}
      display={"flex"}
      justifyContent={{ md: "space-between" }}
      sx={{ placeItems: "center" }}
      className={isMatch ? "bgBannerSmall" : "bgBanner"}
      direction={{ xs: "column-reverse", md: "row" }}
    >
      <Stack my={{ xs: 0, md: 2 }} mx={{ xs: 4, md: 8 }} spacing={4}>
        <Stack
          direction={"row"}
          bgcolor={"#F0F9FF"}
          borderRadius="16px"
          display={"flex"}
          maxWidth="fit-content"
          sx={{ placeContent: "center", px: "6px", py: "6px" }}
          // width={"145px"}
        >
          <Typography
            whiteSpace={"nowrap"}
            bgcolor={"#FFFFFF"}
            borderRadius="16px"
            color="#026AA2"
            textAlign="center"
            px={"6px"}
            py={"1px"}
            sx={{ typography: "textSmMed" }}
          >
            We’re hiring!
          </Typography>
          <Typography
            whiteSpace={"nowrap"}
            bgcolor={"#F0F9FF"}
            borderRadius="16px"
            mx={1}
            px={"4px"}
            py={"1px"}
            sx={{
              typography: "textSmMed",
              display: "flex",
              alignItems: "center",
            }}
            textAlign="center"
            color="#026AA2"
          >
            Careers at IDS{" "}
            <ArrowForwardIcon sx={{ height: "20px", ml: "2px" }} />
          </Typography>
        </Stack>
        <Typography
          sx={{
            typography: { xs: "displayLgBold", md: "displayXlBold" },
          }}
        >
          Join Our Team
        </Typography>
        <Typography
          maxWidth={"sm"}
          sx={{
            typography: { xs: "textMdReg", md: "textLgReg" },
            color: "primary.gray600",
          }}
          my={"16px"}
        >
          With a culture that fosters innovation, creativity, and collaboration,
          we're more than just a workplace – we're a community. We're looking
          for the best and brightest minds to be a part of our journey to
          transform the future.
        </Typography>
        {/* <form onSubmit={onSubscribeHandler}> */}
        <Subscriber inputWidth="400px" type="NOTIFICATION" />

        <Stack>
          <Stack>
            <Typography
              sx={{
                typography: { xs: "textXsReg", md: "textSmReg" },
                color: "primary.gray600",
                width: { md: "400px" },
              }}
            >
              Receive job alerts directly in your inbox. Complete the process by
              clicking 'subscribe' and confirming your agreement to our{" "}
              <a
                href="https://idsil.com/policies/privacy"
                style={{ textDecoration: "none", color: "#475467" }}
              >
                {" "}
                privacy policy
              </a>{" "}
              .
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Banner;
