import React, { useState } from "react";
import { Button, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import PaperContainer from "../layouts/PaperContainer";
import { Link } from "react-router-dom";
import ListItem from "./ListItem";
import Paginate from "../Pagination/Paginate";

const activeCSS = [
  {
    maxWidth: "fit-content",
    color: "primary.blue700",
    bgcolor: "primary.blue50",
    fontSize: "16px",
    fontWeight: "600",
    border: "1px solid",
    borderColor: "primary.blue600",
    padding: "12px 20px",
    textTransform: "capitalize",
    lineHeight: "24px",
  },
  {
    "&:hover": {
      bgcolor: "primary.gray100",
    },
  },
];

const inActiveCSS = [
  {
    maxWidth: "fit-content",
    color: "primary.gray600",
    fontSize: "16px",
    fontWeight: "600",
    padding: "12px 20px",
    textTransform: "capitalize",
    lineHeight: "24px",
  },
  {
    "&:hover": {
      bgcolor: "primary.gray100",
    },
  },
];

const List = (props) => {
  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const itemsPerPage = 5;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedItems =
    props.isActiveButton === "View All"
      ? props.lists?.slice(startIndex, endIndex)
      : props.lists;

  return (
    <PaperContainer my={10}>
      <Stack maxWidth={"xl"} mx={"auto"}>
        <Stack sx={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            sx={{
              typography: { xs: "textSmSemi", md: "textMdSemi" },
              color: "primary.blue700",
            }}
          >
            Work At IDS
          </Typography>
          <Typography
            sx={{ typography: { xs: "displayMdSemi", md: "displayMdSemi" } }}
            my={2}
          >
            Discover Our Current Openings
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              typography: { xs: "textLgReg", md: "textXlReg" },
              mx: { xs: "0rem", md: "10rem" },
              color: "primary.gray600",
            }}
          >
            Explore our open positions and discover how you can become a part of
            our community that values excellence, innovation, and a passion for
            making a positive impact.
          </Typography>
        </Stack>
        <Stack
          mt={5}
          spacing={2}
          rowGap={2}
          direction={"row"}
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            sx={props.isActiveButton === "View All" ? activeCSS : inActiveCSS}
            onClick={(e) => {
              props.setIsActiveButton("View All");
              props.setFilteredList("");
            }}
          >
            View All
          </Button>
          {props.lists
            .filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.job.title === value.job.title)
            )
            .map((list) => {
              return (
                <Button
                  key={list.job.title}
                  sx={
                    props.isActiveButton === list.job.title
                      ? activeCSS
                      : inActiveCSS
                  }
                  onClick={(e) => {
                    props.setFilteredList(list.job.title);
                    props.setIsActiveButton(list.job.title);
                  }}
                >
                  {list.job.title}
                </Button>
              );
            })}
        </Stack>
      </Stack>
      <Stack
        my={10}
        display={"flex"}
        justifyContent="center"
        mx={"auto"}
        maxWidth={"md"}
      >
        {displayedItems
          .filter(
            (value) =>
              value.job.title === props.filteredList ||
              props.filteredList.length === 0
          )
          .map((list) => {
            return (
              <Link
                to={`applications/${list.id}?opening=${list.job.title
                  .toLowerCase()
                  .replace(/\s/g, "-")}&job=${list.title
                  .replace(/\s/g, "-")
                  .replace(/,+/g, "")
                  .replace(/-+/g, "-")}`}
                style={{ all: "unset" }}
                key={list.id}
              >
                <ListItem list={list} />
              </Link>
            );
          })}
        <Divider sx={{ mt: 4 }} />
        {props.isActiveButton === "View All" && (
          <Stack mt={4}>
            <Paginate count={props.lists.length} handleChange={handleChange} />
          </Stack>
        )}
      </Stack>
    </PaperContainer>
  );
};

export default List;
