import React from "react";
// import NewsLetter from "./Footer/NewsLetter";
// import useApi from "../../hooks/use-api";
// import Toast from "../../utils/Toast";
import Address from "./Footer/Address";

// const initialValues = { email: "", checkField: "" };

const Footer = () => {
  // const [values, setValues] = useState(initialValues);
  // const [errors, setErrors] = useState({});
  // const [isLoading, setIsLoading] = useState(false);

  // ? ************************************** form handle change ************************************** */
  // const handleChange = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const error = validationHandler(values);
  //     if (Object.keys(error).length === 0) {
  //       setIsLoading(true);
  //       const result = await useApi.postRequest(
  //         "subscribers",
  //         false,
  //         values,
  //         "REACT_APP_API_KEY_SUBSCRIBER"
  //       );
  //       setIsLoading(false);
  //       if (result.status === "1") {
  //         setValues({ email: "", checkField: "", type: "" });
  //         return Toast.fire({ icon: "success", title: result.message });
  //       }
  //       throw new Error(result.message);
  //     }

  //     return setErrors(error);
  //   } catch (error) {
  //     return Toast.fire({ icon: "error", title: error.message });
  //   }
  // };

  // ? ************************************** Validation handler ************************************** */
  // const validationHandler = (value) => {
  //   const errors = {};
  //   const regex =
  //     /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  //   if (!value.email) {
  //     errors.email = "Please write your email";
  //   } else if (!regex.test(value.email)) {
  //     errors.email = "Email is not valid";
  //   }
  //   return errors;
  // };

  return (
    <>
      {/* <NewsLetter
        values={values}
        handleChange={handleChange}
        isLoading={isLoading}
        errors={errors}
      /> */}
      <Address />
    </>
  );
};

export default Footer;
