import { CircularProgress, Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";

const Uploaded = (props) => {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100px",
        backgroundColor: "white",
        padding: "20px",
        my: "1rem",
        border: "2px solid",
        // borderColor: "primary.primary700",
        borderColor: "primary.blue600",
        borderRadius: "8px",
        position: "relative",
      }}
    >
      <Stack position={"absolute"} left={"8px"} right={"8px"} px={2}>
        <Stack
          display={"flex"}
          direction={"row"}
          justifyContent={"space-between"}
          pb={"0.2rem"}
        >
          <Stack item>
            <Stack display={"flex"} direction={"row"} spacing={2}>
              <Stack item>
                <CloudUploadOutlinedIcon
                  style={{
                    cursor: "pointer",
                    Color: "primary.primary700",
                  }}
                />
              </Stack>
              <Stack item>
                <Typography sx={{ typography: "textSmReg" }}>
                  {props.file?.name || props.fileName}
                </Typography>
                <Typography
                  sx={{
                    typography: "textSmReg",
                    color: "primary.gray77",
                  }}
                >
                  {props.file?.size || props.fileSize}- {props.percentage}%
                  uploaded
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          {!props.isDeleting && (
            <Stack direction={"column"} spacing={1}>
              <Stack item color="primary.primary700">
                <CheckCircleIcon style={{ cursor: "pointer" }} />
              </Stack>
              <Stack item>
                <DeleteIcon
                  style={{
                    cursor: "pointer",
                    Color: "primary.blue600",
                  }}
                  onClick={() => props.onDeleteFileHandler(props.file.file)}
                />
              </Stack>
            </Stack>
          )}
          {props.isDeleting && (
            <Stack color="">
              <CircularProgress
                // color="warning"
                style={{ color: "#026AA2" }}
                size={32}
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: "2%",
                  marginTop: "-10px",
                  marginLeft: "-10px",
                }}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Uploaded;
