const aboutData = [
  {
    link: "https://ehs.idsil.com/",
    title: "Environmental Health & Safety",
    content: `Providing a broad range of regulatory compliance services to assist clients for their global regulatory requirements in the Chemicals, Food & Beverages and Automotive Domains through specialized subject matter experts and researchers`,
  },
  {
    link: "http://healthcare.idsil.com/",
    title: "Healthcare Services",
    content: `State of the art Medical Scribe and Transcription services to assist physicians across practice areas. We cater to Custom-developed Telemedicine/Telehealth apps, EHR Software & RCM Platforms to suit individual practice needs`,
  },
  {
    link: "https://ids-technologies.in/",
    title: "Digital Technology Solutions",
    content: `We strike out a perfect balance while blending technology with human intelligence to provide our clients with the best results for any of their objectives`,
  },
  {
    link: "https://cmp.idsil.com",
    title: "Content Management & Publishing Services",
    content: `Assisting publishers and content service businesses in building smart solutions, from conception and design to new media innovations and outputs`,
  },
  {
    link: "https://ideasengg.com/en_US/",
    title: "Engineering Services",
    content: `With 20+ years of experience in providing engineering solutions, industry specialization of IDEAS includes Aerospace, Automotive, Machinery, Semiconductors, and Medical Equipment`,
  },
];

export default aboutData;
