import React from "react";
import PaperContainer from "../PaperContainer";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { MdLocationPin, MdEmail } from "react-icons/md";
import {
  FaFacebookF,
  FaGlobe,
  FaPrint,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import Link from "@mui/material/Link";

const Address = () => {
  return (
    <PaperContainer bgColor="primary.gray200" color="primary.gray50">
      <Grid container mx={"auto"} maxWidth={"xl"} my="auto" pt={10} pb={3}>
        <Grid item="true" lg={4} my={{ xs: 2, md: 2.5 }}>
          <svg
            width="120"
            height="50"
            viewBox="0 0 65 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.16113 20.6866V0.5H6.07304V20.6866H1.16113Z"
              stroke="white"
            />
            <path
              d="M36.4798 2.99411V18.099C36.4873 19.7938 34.7774 21.2156 33.0977 21.1866H10.6324V20.6866M36.4798 2.99411C36.4411 1.83344 34.9634 0.00752289 33.1912 0M36.4798 2.99411H35.9798V3.0033M36.4798 2.99411L35.98 3.01077C35.9799 3.00829 35.9799 3.0058 35.9798 3.0033M33.1912 0H10.6324V0.5M33.1912 0L33.1891 0.499995C33.1894 0.499997 33.1897 0.499998 33.1901 0.5M33.1912 0V0.5H33.1901M10.6324 0.5H10.1324V20.6866H10.6324M10.6324 0.5V20.6866M10.6324 0.5H33.1901M10.6324 20.6866H33.0977H33.1063V20.6867C33.8068 20.6988 34.5359 20.4056 35.0899 19.9149C35.6432 19.425 35.9827 18.775 35.9798 18.1012L35.9797 18.099L35.9798 3.0033M33.1901 0.5C33.9039 0.503377 34.5963 0.876476 35.13 1.41619C35.6709 1.9632 35.9628 2.59273 35.9798 3.0033M16.0433 4.18891H15.5432L15.5433 4.68896L15.5444 15.94L15.5444 16.4399H16.0444H30.5743H31.0743V15.9399V4.68891V4.18891H30.5743H16.0433Z"
              stroke="white"
            />
            <path
              d="M40.4456 2.97154H40.4458L40.4454 2.95773C40.4315 2.45466 40.7466 1.8457 41.2723 1.32888C41.798 0.812008 42.406 0.513555 42.816 0.5H60.5744C61.2901 0.503379 61.9823 0.876631 62.5141 1.41575C63.0541 1.96321 63.3424 2.59244 63.3555 3.00129V5.95493L58.8542 5.96724V5.19939V4.69939H58.3542H46.0435H45.5435V5.19939V6.45356V6.49327L45.5497 6.53247C45.5915 6.79376 45.7468 6.98852 45.905 7.12455C46.0638 7.26115 46.2622 7.37114 46.4717 7.46296C46.8917 7.64703 47.4505 7.79815 48.0818 7.92649C49.351 8.18452 51.0208 8.37116 52.716 8.51466C54.31 8.64959 55.9438 8.74771 57.3134 8.82996C57.4036 8.83537 57.4927 8.84072 57.5806 8.84601C59.0258 8.93299 60.0907 9.00109 60.4856 9.07331L60.4991 9.07579L60.5128 9.07751C61.262 9.1723 61.9761 9.59397 62.5124 10.143C63.0579 10.7015 63.3425 11.31 63.3555 11.7149L63.3555 18.0947L63.3555 18.0972C63.359 18.7693 63.021 19.4197 62.4696 19.9101C61.9175 20.4013 61.1907 20.6949 60.491 20.6824L60.491 20.6823H60.482H43.3142C42.6494 20.675 41.9075 20.3418 41.3337 19.8006C40.7592 19.2587 40.4184 18.5719 40.4452 17.9141L40.4456 17.9039V17.8937V15.2879H45.3113V15.8459C45.2934 16.3163 45.4406 16.715 45.7899 16.9729C46.1007 17.2023 46.4922 17.256 46.8134 17.2682L46.8228 17.2685H46.8323H56.9556C57.3056 17.2763 57.699 17.2292 58.016 17.0054C58.3677 16.7571 58.5245 16.3669 58.5296 15.9143H58.5296V15.9088V14.9596C58.5448 14.5372 58.2915 14.2343 58.0169 14.0412C57.7443 13.8495 57.3808 13.7105 56.9878 13.6019C56.194 13.3825 55.1095 13.243 53.9311 13.1388C52.9145 13.0489 51.8015 12.9832 50.7079 12.9187C50.527 12.908 50.3466 12.8974 50.1673 12.8867C48.898 12.8108 47.6824 12.7313 46.6759 12.6117C43.6775 12.2474 42.1198 11.6417 41.3165 11.0427C40.9238 10.7498 40.7157 10.4617 40.6005 10.203C40.4844 9.94236 40.4494 9.68166 40.4456 9.42165V2.97154Z"
              stroke="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.8225 20.9308V19.035H23.5576V20.3891H24.2852V16.273C24.2766 15.251 26.7763 14.0516 26.7065 15.2822L26.7376 17.8356V20.675H27.4577V17.6959L28.1455 17.7045C28.1455 18.6793 28.1541 19.6927 28.1541 20.675H28.5098V18.0603C28.556 17.9281 28.4636 17.495 28.5635 17.3639C29.3448 16.3655 30.6839 19.4681 29.9252 15.3671C28.6721 14.2376 30.7301 12.4428 29.3878 12.1634C29.4889 10.7942 30.1541 9.36917 28.2196 9.22301V8.59646C29.0869 7.5755 28.7151 7.28856 28.297 7.28856C27.1439 7.28103 26.6183 7.482 27.059 8.55777L27.0493 9.18862C26.2293 9.72597 25.7188 10.4955 26.0358 11.5014C25.7962 11.6174 25.1148 11.7958 24.9912 11.4401C24.8677 11.0844 25.0299 10.1397 24.9063 9.82807C24.9526 9.37132 24.6506 9.04676 23.5984 9.02312V8.41054C23.8531 8.27943 24.1551 8.11715 24.2433 7.72273C24.2196 6.87157 23.6855 6.66953 22.9353 6.88662C22.1766 7.24234 22.3314 7.78399 22.9042 8.41054V9.10695H22.4281C21.4995 9.09942 21.3287 9.79583 21.3835 10.6857C21.1513 11.2348 20.9267 11.2348 20.6946 10.6857C20.6785 10.2451 20.787 9.2617 19.4716 9.30039V8.62763C19.53 8.63525 19.5893 8.62617 19.6428 8.60145C19.6962 8.57672 19.7416 8.53736 19.7736 8.48792C20.0294 7.95057 19.9283 7.21977 19.1932 7.10371C18.226 7.18861 18.0863 8.06234 18.5119 8.65127L18.5044 9.31651C17.1664 9.41646 17.2147 9.96133 17.29 12.489H16.8257V13.6422C17.1277 14.1602 17.003 14.5621 16.8257 14.9415V16.8534H17.9552L17.9401 20.459H18.8526L18.8601 16.489H19.5651V19.3295H20.3539V14.5621C20.2916 13.5025 21.4759 13.9173 21.3749 14.7771L21.3362 17.725H22.1411V20.9286L22.8225 20.9308Z"
              fill="white"
            />
            <path
              d="M6.87549 23.7722L6.85658 23.375C6.71472 23.0629 5.82573 23.0912 5.52309 23.0912C2.40217 23.0912 1.03085 24.5382 1.03085 25.465C1.03085 25.9947 1.47535 26.4864 3.62216 26.5526V26.581C0.841704 27.2714 0 28.4819 0 29.1156C0 29.4939 0.255348 30.0708 0.662014 30.1843C1.07814 30.2977 1.51318 30.3829 1.95767 30.3923C3.89643 30.4301 5.12588 29.5884 5.44743 29.3142C5.99596 28.8602 6.04324 28.4819 5.99596 27.801C5.37177 28.1509 4.77596 28.5954 4.09503 28.8035C3.13984 29.0967 2.38325 29.1629 1.8631 29.1345C1.3524 29.1156 1.06868 29.0021 1.06868 28.8319C1.06868 28.4914 2.07116 27.4227 4.49224 27.0255C4.68139 26.9971 5.24883 26.9026 5.39069 26.7796C5.52309 26.6756 5.74061 26.3351 5.74061 26.1649C5.74061 25.9474 5.20154 25.9568 5.10697 25.9568C2.07116 26.023 2.1279 25.7488 2.09953 25.5974C2.04279 25.2664 3.33844 24.3491 5.57983 24.368C5.40014 24.5098 4.92728 24.9071 4.92728 25.0395C4.92728 25.0962 4.99348 25.1435 5.03131 25.134C5.19208 25.1057 5.74061 24.7274 6.35534 24.5193C6.88495 24.3491 6.90386 24.3112 6.87549 23.7722Z"
              fill="white"
            />
            <path
              d="M10.3005 28.1982C10.1302 28.3401 9.7141 28.6711 9.48712 28.6711C9.39255 28.6711 9.31689 28.586 9.31689 28.5008C9.31689 27.8483 10.4801 27.1295 10.4801 26.7607C10.4518 26.5621 10.395 26.3919 10.3383 26.25C10.2815 26.1176 10.2248 26.0703 10.187 26.0703C9.9127 26.0703 8.17255 27.2336 7.41596 28.1982C7.37813 28.2455 7.29302 28.3306 7.22682 28.3306C7.17007 28.3306 7.12279 28.2833 7.12279 28.2266C7.12279 27.9996 8.31441 27.0444 8.31441 26.6756C8.31441 26.6188 8.3617 26.4864 7.79426 26.4675C7.63348 26.4675 7.54837 26.477 7.42542 26.581C6.87689 27.0444 6.28108 27.9996 6.30945 28.6522C6.31891 28.9737 6.42294 29.5695 6.83906 29.5695C7.09441 29.5695 7.44433 29.1061 7.5862 28.9264C8.01178 28.3779 8.4941 27.8861 9.04263 27.4795L9.06154 27.4984C8.75891 27.9334 8.24821 28.5576 8.24821 29.125C8.24821 29.4466 8.41844 29.7492 8.77782 29.7492C9.46821 29.7492 10.4423 28.586 10.9436 28.1415L10.8679 27.7253L10.3005 28.1982Z"
              fill="white"
            />
            <path
              d="M13.7142 26.9782L13.7331 26.9971C11.8605 28.8886 11.5674 28.8602 11.3782 28.8981C11.2364 28.9264 11.1985 28.7562 11.1891 28.7089C11.1891 28.2455 11.4633 27.6686 13.7142 26.9782ZM13.2035 28.255L13.2224 28.2739C13.0995 28.5292 12.8725 29.0305 12.8725 29.3331C12.8725 29.6074 13.0143 29.8343 13.3264 29.8343C13.7898 29.8343 15.0571 28.8224 15.4638 28.359L15.3881 28.0091C14.9815 28.2833 14.4424 28.7657 14.0452 28.7657C13.8939 28.7657 13.752 28.6995 13.752 28.5387C13.752 28.0942 14.5748 27.4322 14.5748 27.1295C14.5748 26.9215 14.4802 26.8174 14.3005 26.7418C14.3857 26.7134 14.6315 26.6188 14.6315 26.5053C14.6315 26.3919 14.4519 26.0419 14.0168 26.0514C12.9198 26.0798 10.4609 27.2903 10.4987 28.4725C10.5176 28.9643 11.0661 29.5884 11.3309 29.5884C11.5485 29.5884 12.1537 29.0967 12.3334 28.9548L13.2035 28.255Z"
              fill="white"
            />
            <path
              d="M17.623 26.3635C18.2282 25.7771 19.6279 24.3585 19.6279 23.4979C19.6279 23.3182 19.5428 23.148 19.3536 23.148C18.9564 23.148 17.4716 24.3018 16.7056 25.2192C15.9585 26.1081 15.4005 27.1201 15.4005 28.3117C15.4005 28.7657 15.4856 29.683 16.1003 29.683C16.7245 29.683 17.3865 29.1061 17.7932 28.6805C18.3985 28.6711 18.8808 28.5198 19.3064 28.0753L19.2118 27.7632C18.9092 27.9807 18.5971 28.1888 18.2093 28.1131C18.3701 27.7632 18.6633 27.3281 18.6633 26.9309C18.6633 26.7512 18.6065 26.5148 18.3795 26.5148C17.8216 26.5148 17.065 27.4416 17.065 27.9902C17.065 28.1415 17.1217 28.236 17.1974 28.359C17.0366 28.5481 16.8664 28.8035 16.5637 28.8508C16.2043 28.8981 15.9774 28.4063 16.1476 27.8105L17.623 26.3635ZM18.8335 23.9613L18.8524 23.9802C18.6916 24.3207 18.4836 24.8598 16.507 26.8269L16.4881 26.808C17.0461 25.7109 17.8972 24.7557 18.8335 23.9613Z"
              fill="white"
            />
            <path
              d="M20.218 26.912C20.6625 25.9379 21.2015 25.0867 21.6649 24.8598C21.4947 25.3988 20.9083 26.3067 20.2369 26.9309L20.218 26.912ZM22.0621 27.9145C21.6838 28.255 20.9556 28.8602 20.4355 28.8602C20.1801 28.8602 20.0477 28.6711 19.9626 28.4536C19.9153 28.2266 19.9437 27.9618 20.0288 27.801C22.0811 25.815 22.3269 24.9827 22.3931 24.0843C22.4026 23.9613 22.3648 23.7343 22.204 23.7343C21.9014 23.7343 21.0029 24.6139 20.1707 25.6447C19.5559 26.4013 19.1304 27.47 19.1304 28.463C19.1304 29.021 19.2817 29.6925 19.9721 29.6925C20.7854 29.6925 21.6744 28.7373 22.1945 28.1982L22.0621 27.9145Z"
              fill="white"
            />
            <path
              d="M24.6043 28.0091C24.226 28.2833 23.7342 28.7846 23.233 28.7846C22.798 28.7846 22.6561 28.5765 22.7318 28.1888C23.129 27.9145 24.0653 27.0728 24.0653 26.5999C24.0653 26.3919 23.8099 26.3351 23.6491 26.3351C23.0817 26.3351 21.8995 27.4605 21.8995 28.6143C21.8995 29.0967 22.1549 29.7398 22.7128 29.7398C23.4316 29.7398 24.2639 28.8886 24.6894 28.3684L24.6043 28.0091ZM24.1598 26.146C24.5665 26.146 24.9164 25.6826 24.9164 25.3043C24.9164 25.134 24.7746 25.0111 24.6043 25.0111C24.2355 25.0111 23.7815 25.3516 23.7815 25.7393C23.7815 25.9663 23.9328 26.146 24.1598 26.146Z"
              fill="white"
            />
            <path
              d="M28.5132 28.1982C28.343 28.3401 27.9269 28.6711 27.6999 28.6711C27.6053 28.6711 27.5297 28.586 27.5297 28.5008C27.5297 27.8483 28.6929 27.1295 28.6929 26.7607C28.6646 26.5621 28.6078 26.3919 28.5511 26.25C28.4943 26.1176 28.4376 26.0703 28.3997 26.0703C28.1255 26.0703 26.3853 27.2336 25.6287 28.1982C25.5909 28.2455 25.5058 28.3306 25.4396 28.3306C25.3829 28.3306 25.3356 28.2833 25.3356 28.2266C25.3356 27.9996 26.5272 27.0444 26.5272 26.6756C26.5272 26.6188 26.5745 26.4864 26.007 26.4675C25.8463 26.4675 25.7611 26.477 25.6382 26.581C25.0897 27.0444 24.4939 27.9996 24.5222 28.6522C24.5317 28.9737 24.6357 29.5695 25.0518 29.5695C25.3072 29.5695 25.6571 29.1061 25.799 28.9264C26.2246 28.3779 26.7069 27.8861 27.2554 27.4795L27.2743 27.4984C26.9717 27.9334 26.461 28.5576 26.461 29.125C26.461 29.4466 26.6312 29.7492 26.9906 29.7492C27.681 29.7492 28.6551 28.586 29.1563 28.1415L29.0807 27.7253L28.5132 28.1982Z"
              fill="white"
            />
            <path
              d="M30.8677 29.5222C30.3476 29.9005 28.9101 31.0922 28.9101 31.7731C28.9101 31.896 28.9952 32.0001 29.1276 32.0001C29.4964 32.0001 30.3287 31.3948 31.3217 29.8154L33.3172 28.3212L33.2699 27.924L31.8324 28.9548L31.8135 28.9359L32.5228 27.6119C32.589 27.4889 32.7214 27.2714 32.7214 27.1295C32.7214 26.9688 32.5512 26.6472 32.362 26.6472C32.2107 26.6472 32.0972 26.808 32.0026 26.912L30.9907 28.028C30.6313 28.4157 30.0544 28.6427 29.7896 28.6143C29.7045 28.6143 29.6477 28.5954 29.6383 28.5198C29.5532 27.8294 30.253 27.5646 30.8488 27.243L31.927 26.6567C32.0499 26.5716 32.2391 26.4391 32.2391 26.2689C32.2391 26.0609 31.8702 26.023 31.7378 26.0325C31.0096 26.0987 28.9479 27.1012 28.9479 28.3401C28.9479 28.7562 29.1276 29.4466 29.6572 29.4466C30.1774 29.4466 31.1704 28.586 31.5108 28.2077L31.5298 28.2266L30.8677 29.5222Z"
              fill="white"
            />
            <path
              d="M38.7382 25.5502C38.7476 25.3326 39.7974 24.4531 42.2374 24.368L42.2563 24.3869C41.7456 24.7368 41.5659 25.03 41.5659 25.0489C41.5659 25.1435 41.6227 25.1908 41.7172 25.1908C41.7929 25.1908 41.8875 25.134 41.9537 25.0962C42.3225 24.8314 42.3982 24.7841 42.8048 24.6328C43.3628 24.4153 43.5993 24.2545 43.5993 23.5547C43.5993 23.3655 43.5709 23.2899 43.3534 23.2426C42.9562 23.1574 42.5589 23.1102 42.1617 23.1102C40.0906 23.1102 38.3788 24.0086 38.0856 24.4909C37.9343 24.7463 37.783 25.4367 37.783 25.7488C37.783 26.3162 38.2748 26.4675 38.7476 26.5432C39.2016 26.6188 41.8213 26.9971 41.8213 27.4795C41.8213 27.6308 40.8945 28.6049 37.5182 28.4819V28.463C37.5749 28.4063 37.6222 28.359 37.679 28.3212C37.7452 28.2833 37.8397 28.236 37.8397 28.1415C37.8397 28.0847 37.8114 28.0753 37.7641 28.0753C37.6222 28.0753 37.0642 28.255 36.9507 28.3495C36.7994 28.4914 36.4684 28.9926 36.4684 29.1912C36.4684 29.7398 37.6222 29.7398 37.991 29.7398C39.3529 29.7398 42.6535 29.4371 42.6535 27.5551C42.6535 27.0917 42.209 26.5905 41.2538 26.3635C38.6152 25.7488 38.7382 25.6826 38.7382 25.5502Z"
              fill="white"
            />
            <path
              d="M44.8941 27.924C44.4496 28.3212 43.9294 28.5954 43.7592 28.5954C43.693 28.5954 43.6836 28.5008 43.6836 28.4536C43.6836 28.0564 43.9105 27.8483 44.2037 27.6402C44.5725 27.3754 44.6293 27.2903 44.9225 26.9593C45.0265 26.8742 45.2818 26.6094 45.3008 26.4581C45.3386 26.1743 44.8563 26.2595 44.7239 26.2595C44.5631 26.2595 44.4401 26.2784 44.3077 26.3635C43.7025 26.7607 42.9932 27.7064 42.9932 28.6238C42.9932 29.0305 43.2485 29.5601 43.7119 29.5601C44.2321 29.5601 44.8657 28.7089 45.2346 28.3874L45.2535 28.4063C45.1589 28.6522 45.0549 28.917 45.0549 29.2007C45.0549 29.5317 45.2629 29.8249 45.6129 29.8249C46.3127 29.8249 47.3152 28.7373 47.7975 28.2739L47.7408 27.8672C47.457 28.0942 46.6437 28.7562 46.2843 28.7562C46.133 28.7562 46.0479 28.6995 46.0479 28.5481C46.0479 28.1226 46.7194 27.3754 47.022 27.1012C47.1733 26.9688 47.4287 26.7512 47.4097 26.5243C47.3719 26.0325 47.2679 26.0041 47.1922 26.0041C46.9558 26.0041 46.029 26.9026 45.8304 27.0822L44.8941 27.924Z"
              fill="white"
            />
            <path
              d="M49.4955 27.2241C49.5049 27.4038 49.5995 27.6402 49.817 27.6402C50.0724 27.6402 50.8195 26.912 50.8195 26.3919C50.8195 26.1081 50.6871 25.9568 50.3939 25.9568C49.8643 25.9568 48.9564 26.4675 48.5308 26.7891C47.9161 27.2525 47.6324 27.5457 47.6324 28.3401C47.6324 29.0399 47.9823 29.7208 48.7105 29.7208C49.609 29.7208 50.6682 28.7846 51.2451 28.1698L51.1316 27.801C50.5831 28.2266 49.7603 28.8886 49.0415 28.8886C48.5497 28.8886 48.3228 28.5954 48.3228 28.3779C48.3228 28.1131 48.493 27.9712 48.701 27.8199L49.4955 27.2241Z"
              fill="white"
            />
            <path
              d="M52.8942 27.2241C52.9037 27.4038 52.9982 27.6402 53.2157 27.6402C53.4711 27.6402 54.2182 26.912 54.2182 26.3919C54.2182 26.1081 54.0858 25.9568 53.7926 25.9568C53.263 25.9568 52.3551 26.4675 51.9295 26.7891C51.3148 27.2525 51.0311 27.5457 51.0311 28.3401C51.0311 29.0399 51.381 29.7208 52.1092 29.7208C53.0077 29.7208 54.0669 28.7846 54.6438 28.1698L54.5303 27.801C53.9818 28.2266 53.159 28.8886 52.4402 28.8886C51.9485 28.8886 51.7215 28.5954 51.7215 28.3779C51.7215 28.1131 51.8917 27.9712 52.0998 27.8199L52.8942 27.2241Z"
              fill="white"
            />
            <path
              d="M57.8818 27.8294C57.3805 28.2266 56.5861 28.917 55.9146 28.917C55.612 28.917 55.2999 28.794 55.3283 28.4346L55.3472 28.2266C57.163 27.6308 57.6264 26.9309 57.6264 26.4675C57.6264 26.0892 57.2481 26.0136 56.9455 26.0136C55.716 26.0041 54.3447 27.2714 54.3825 28.5103C54.4015 29.1345 54.723 29.7492 55.4134 29.7492C56.3497 29.7492 57.39 28.8602 57.9669 28.1888L57.8818 27.8294ZM55.4039 27.7632C55.6593 27.139 56.2078 26.685 56.6807 26.5621C56.7469 26.5432 56.7942 26.5999 56.7942 26.6661C56.8036 26.8269 56.2362 27.4795 55.4039 27.7632Z"
              fill="white"
            />
            <path
              d="M59.276 26.8931C59.3327 27.2147 59.3706 27.47 59.3989 27.6686C59.3706 28.2171 59.2192 28.3779 58.6518 28.7278C58.4721 28.4819 58.264 28.1888 57.9141 28.1698L59.276 26.8931ZM60.5622 28.4063C60.0988 28.7562 59.7299 29.0399 59.1247 28.9359C59.7299 28.5671 60.3541 27.9902 60.3541 27.2147C60.3541 26.7985 60.2123 26.477 60.1744 26.1176C60.1461 25.8528 60.0893 25.7677 59.8056 25.7677C59.4651 25.7677 59.2854 26.0703 59.2665 26.3824L57.2143 28.2644C57.1386 28.3401 56.9873 28.4725 56.9873 28.5954C56.9873 28.6805 57.0535 28.7751 57.1481 28.7751C57.2332 28.7751 57.2994 28.7089 57.3561 28.6522C57.5264 29.3426 58.2262 29.7019 58.8599 29.7303C59.6164 29.7681 60.6473 28.9075 61.2242 28.3306L61.1296 27.9712L60.5622 28.4063Z"
              fill="white"
            />
            <path
              d="M62.6747 26.8931C62.7315 27.2147 62.7693 27.47 62.7977 27.6686C62.7693 28.2171 62.618 28.3779 62.0505 28.7278C61.8708 28.4819 61.6628 28.1888 61.3129 28.1698L62.6747 26.8931ZM63.9609 28.4063C63.4975 28.7562 63.1287 29.0399 62.5234 28.9359C63.1287 28.5671 63.7528 27.9902 63.7528 27.2147C63.7528 26.7985 63.611 26.477 63.5732 26.1176C63.5448 25.8528 63.488 25.7677 63.2043 25.7677C62.8639 25.7677 62.6842 26.0703 62.6653 26.3824L60.613 28.2644C60.5374 28.3401 60.386 28.4725 60.386 28.5954C60.386 28.6805 60.4522 28.7751 60.5468 28.7751C60.6319 28.7751 60.6981 28.7089 60.7549 28.6522C60.9251 29.3426 61.6249 29.7019 62.2586 29.7303C63.0152 29.7681 64.046 28.9075 64.6229 28.3306L64.5284 27.9712L63.9609 28.4063Z"
              fill="white"
            />
          </svg>
          <Stack
            direction={"row"}
            spacing={2}
            m={2}
            alignitem="true"
            s="center"
            color={"white"}
          >
            <Stack item="true">
              <a href="https://www.facebook.com/IDSINFOTECHLTD/">
                <FaFacebookF color={"white"} />
              </a>
            </Stack>
            <Stack item="true">
              <a href="https://twitter.com/IDSInfotechLTD">
                <FaTwitter color={"white"} />
              </a>
            </Stack>
            <Stack item="true">
              <a href="https://www.linkedin.com/company/idsinfotech/">
                <FaLinkedinIn color={"white"} />
              </a>
            </Stack>
          </Stack>
        </Grid>
        <Grid item="true" lg={8}>
          <Grid container justifyContent="space-between">
            <Grid item="true" lg={4} my={{ xs: 2 }}>
              <Typography
                sx={{ typography: { xs: "textXlSemi", md: "textXlbold" } }}
              >
                OFFICE ADDRESS
              </Typography>
              <Box my={2} sx={{ lineHeight: "1.2" }}>
                <Stack spacing={1} direction="row" sx={{ pb: "8px" }}>
                  <Stack item="true">
                    <MdLocationPin />
                  </Stack>
                  <Stack item="true">
                    <Typography sx={{ typography: { md: "textSmReg" } }}>
                      C-138, Phase VIII, Industrial Area, Mohali (India)
                    </Typography>
                  </Stack>
                </Stack>
                <Stack spacing={1} direction="row" sx={{ pb: "8px" }}>
                  <Stack item="true">
                    <MdEmail />
                  </Stack>
                  <Stack item="true">
                    <Typography sx={{ typography: { md: "textSmReg" } }}>
                      <Link
                        style={{ color: "white" }}
                        href="mailto:info@idsil.com"
                        underline="none"
                      >
                        info@idsil.com
                      </Link>
                    </Typography>
                  </Stack>
                </Stack>
                <Stack spacing={1} direction="row" sx={{ pb: "8px" }}>
                  <Stack item="true">
                    <FaGlobe />
                  </Stack>
                  <Stack item="true">
                    <Link
                      style={{ color: "white" }}
                      href="https://idsil.com/"
                      underline="none"
                    >
                      <Typography sx={{ typography: { md: "textSmReg" } }}>
                        www.idsil.com
                      </Typography>
                    </Link>
                  </Stack>
                </Stack>
                <Stack spacing={1} direction="row" sx={{ pb: "8px" }}>
                  <Stack item="true" my={2}>
                    <BsFillTelephoneOutboundFill />
                  </Stack>
                  <Stack item="true">
                    <Typography sx={{ typography: { md: "textSmReg" } }}>
                      (+91) 172 505 3601
                    </Typography>
                    <Typography sx={{ typography: { md: "textSmReg" } }}>
                      (+91) 172 505 3602
                    </Typography>
                  </Stack>
                </Stack>
                <Stack spacing={1} direction="row" sx={{ pb: "8px" }}>
                  <Stack item="true">
                    <FaPrint />
                  </Stack>
                  <Stack item="true" sx={{ typography: { md: "textSmReg" } }}>
                    (+91) 172 509 8475
                  </Stack>
                </Stack>
              </Box>
            </Grid>
            <Grid item="true" lg={3} my={{ xs: 2 }}>
              <Typography
                sx={{ typography: { xs: "textXlSemi", md: "textXlbold" } }}
              >
                IMPORTANT LINKS
              </Typography>
              <Box my={2}>
                <Typography sx={{ typography: { md: "textSmReg" } }}>
                  <Link
                    style={{ color: "white" }}
                    href="https://idsil.com/about.php"
                    underline="none"
                  >
                    About Company
                  </Link>
                </Typography>
                <Typography sx={{ typography: { md: "textSmReg" } }} py={0.2}>
                  <Link
                    style={{ color: "white" }}
                    href="https://idsil.com/eventgallery.php"
                    underline="none"
                  >
                    Gallery
                  </Link>
                </Typography>
                <Typography sx={{ typography: { md: "textSmReg" } }} py={0.2}>
                  <Link
                    style={{ color: "white" }}
                    href="https://careers.idsil.com"
                    underline="none"
                  >
                    Join IDS
                  </Link>
                </Typography>
                <Typography sx={{ typography: { md: "textSmReg" } }}>
                  <Link
                    style={{ color: "white" }}
                    href="https://idsil.com/contact-us"
                    underline="none"
                  >
                    Write to Us
                  </Link>
                </Typography>
              </Box>
            </Grid>
            <Grid item="true" lg={4} my={{ xs: 2 }}>
              <Typography
                sx={{ typography: { xs: "textXlSemi", md: "textXlbold" } }}
              >
                NEWS & EVENTS
              </Typography>
              <Box my={2}>
                <Typography sx={{ typography: { md: "textSmReg" } }}>
                  <Link
                    style={{ color: "white" }}
                    href="https://idsil.com/news.php"
                    underline="none"
                  >
                    IDS Infotech collaboration with Digital Enact for expanding
                    footprints in Australia and New Zealand May 06, 2020 View
                    More
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Stack mx={"auto"} maxWidth={"xl"} my="auto">
        <Divider my={2} light={true} sx={{ bgcolor: "primary.gray50" }} />
        <Typography
          variant="subtitle2"
          py={2}
          sx={{ typography: { md: "textSmReg" } }}
        >
          © 2024-25 IDS Infotech Ltd. All Rights Reserved. |{" "}
          <a
            href="https://idsil.com/policies/privacy/"
            style={{ textDecoration: "none", color: "white" }}
          >
            Privacy policy
          </a>{" "}
          |{" "}
          <a
            href="https://idsil.com/policies/terms-and-conditions/"
            style={{ textDecoration: "none", color: "white" }}
          >
            {" "}
            Terms and conditions
          </a>{" "}
          |{" "}
          <a
            href="https://idsil.com/policies/cookies/"
            style={{ textDecoration: "none", color: "white" }}
          >
            Cookies
          </a>
        </Typography>
      </Stack>
    </PaperContainer>
  );
};

export default Address;
