import React, { Suspense, useEffect, useState } from "react";
import {
  Await,
  Link,
  Outlet,
  defer,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import { Stack } from "@mui/system";
import { Tabs, Tab, Box } from "@mui/material";
import useApi from "../hooks/use-api";
import Heading from "../components/application/Heading";
import A11yProps from "../utils/AllyProps";
import GoToTop from "../utils/GoToTop";
import JDHeaderSkeleton from "../components/layouts/skeleton/JDHeaderSkeleton";
import JDSkeleton from "../components/layouts/skeleton/JDSkeleton";

const Application = () => {
  const { opening } = useLoaderData();
  const [value, setValue] = useState(0);
  const location = useLocation();

  useEffect(() => {
    setValue(
      location.pathname.split("/")[3] === undefined
        ? 0
        : location.pathname.split("/")[3] === "apply-now"
        ? 1
        : 2
    );
  }, [location.pathname]);

  return (
    <>
      <Suspense
        fallback={
          <>
            <JDHeaderSkeleton />
            <JDSkeleton />
          </>
        }
      >
        <Await resolve={opening}>
          {(loadedOpening) => (
            <>
              <Heading opening={loadedOpening} />
              <Stack
                display={"flex"}
                my={6}
                justifyContent="center"
                alignItems={"center"}
              >
                <Box sx={{ width: { xs: "95%", md: "70%" }, px: 3 }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      sx={{
                        ".Mui-selected": {
                          color: "secondary",
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: { xs: "16px", md: "18px" },
                          lineHeight: "28px",
                          textAlign: "left",
                        },
                      }}
                      textColor={"secondary"}
                      value={value}
                      indicatorColor="secondary"
                      onChange={(e, value) => setValue(value)}
                      variant="fullWidth"
                      aria-label="basic tabs example"
                    >
                      <Tab
                        component={Link}
                        to=""
                        // state={{ setValue: setValue }}
                        sx={[
                          {
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: { xs: "14px", md: "16px" },
                            lineHeight: "28px",
                            textAlign: "left",
                          },
                        ]}
                        label="Overview"
                        {...A11yProps(0)}
                      />

                      <Tab
                        component={Link}
                        to="apply-now"
                        sx={[
                          {
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: { xs: "14px", md: "16px" },
                            lineHeight: "28px",
                            textAlign: "left",
                          },
                        ]}
                        label="Application"
                        {...A11yProps(1)}
                      />
                      <Tab
                        component={Link}
                        to="referral"
                        sx={[
                          {
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: { xs: "14px", md: "16px" },
                            lineHeight: "28px",
                            textAlign: "left",
                          },
                        ]}
                        label="Referral"
                        {...A11yProps(2)}
                      />
                    </Tabs>
                  </Box>
                </Box>
                <Outlet context={{ setValue, opening: loadedOpening }} />
              </Stack>
            </>
          )}
        </Await>
      </Suspense>
      <GoToTop />
    </>
  );
};

export default Application;

// ? ***************************************************************** opening Data ***************************************************************** */
const loadOpening = async (id) => {
  try {
    const opening = await useApi.getRequest(`openings/${id}`, false);
    if (opening.status) {
      return opening.data;
    }

    throw new Error(opening.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ***************************************************************** departments Data ***************************************************************** */
const loadDepartments = async (id) => {
  try {
    const departments = await useApi.getRequest(`departments`, false);
    if (departments.status) {
      return departments.data;
    }

    throw new Error(departments.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ***************************************************************** Jobs Data ***************************************************************** */
const loadJobs = async () => {
  try {
    const jobs = await useApi.getRequest("jobs", false);
    if (jobs.status) {
      return jobs.data;
    }

    throw new Error(jobs.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ***************************************************************** Jobs Data ***************************************************************** */
const loadCountries = async () => {
  try {
    const jobs = await useApi.getRequest("countries", false);
    if (jobs.status) {
      return jobs.data;
    }

    throw new Error(jobs.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ***************************************************************** Cities Data ***************************************************************** */
const loadCities = async () => {
  try {
    const jobs = await useApi.getRequest("cities", false);
    if (jobs.status) {
      return jobs.data;
    }

    throw new Error(jobs.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ***************************************************************** export loaders ***************************************************************** */
export const loader = async ({ request, params }) => {
  const id = params.id;
  return defer({
    // ? not awaiting the opening for adding the skeleton on the page
    opening: loadOpening(id),
    departments: await loadDepartments(),
    jobs: await loadJobs(),
    cities: await loadCities(),
    countries: await loadCountries(),
  });
};
