import React from "react";
import { Box, Button, IconButton, Toolbar } from "@mui/material";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import PaperContainer from "../PaperContainer";
import logo from "../../../assets/svg/logo.svg";

const NavToolBar = (props) => {
  return (
    <PaperContainer bgColor="grey.main">
      <Toolbar
        disableGutters
        sx={{
          display: "flex",
          padding: "0px 0px",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: "xl",
          mx: "auto",
        }}
      >
        <Stack display={"flex"} flexDirection="row">
          <Stack display={"flex"} alignSelf="center" sx={{ cursor: "pointer" }}>
            <Link
              to="/"
              style={{
                textDecoration: "none",
              }}
            >
              <Box
                component={"img"}
                src={logo}
                alt="logo"
                style={{ width: "65px", verticalAlign: "middle" }}
              />
            </Link>
          </Stack>
        </Stack>
        <Stack
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "row",
            direction: "initial",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px",
            fontSize: "16px",
            gap: "8px",
          }}
        >
          <Link
            to="/about-us"
            style={{
              textDecoration: "none",
              // color: "#F2F4F7",
            }}
            //   className={({ isActive }) =>
            //     isActive ? classes.active : classes.inactive
            //   }
          >
            <Button size="medium" sx={props.buttonStyle}>
              About Us
            </Button>
          </Link>
          <Link
            to="https://idsil.com/"
            style={{
              textDecoration: "none",
              // color: "#F2F4F7",
            }}
            //   className={({ isActive }) =>
            //     isActive ? classes.active : classes.inactive
            //   }
          >
            <Button size="medium" sx={props.buttonStyle}>
              Back to Corporate
            </Button>
          </Link>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "0px",
              gap: "8px",
              display: { xs: "none", md: "flex" },
            }}
          >
            <Button
              size="medium"
              href="https://idsil.com/contact-us/"
              sx={[
                {
                  color: "white",
                  bgcolor: "primary.blue600",
                  fontSize: "16px",
                  fontWeight: "600",
                  padding: "12px 20px",
                  lineHeight: "24px",
                },
                {
                  "&:hover": {
                    bgcolor: "primary.blue700",
                  },
                },
              ]}
            >
              Get in touch
            </Button>
          </Stack>
        </Stack>
        <Stack sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton>
            <MenuIcon onClick={() => props.setIsOpen(!props.isOpen)} />
          </IconButton>
        </Stack>
      </Toolbar>
    </PaperContainer>
  );
};

export default NavToolBar;
