// import avatar from "../assets/svg/avatar.svg";

const testimonials = [
  {
    heading: `Joining IDS has been an incredible journey for me. The company is committed to employees' development, well-being, and satisfaction. What I appreciate most is how the company encourages us to think creatively and try out new things to solve complex problems.`,
    // avatar: avatar,
    name: "Sandeep Singh Koli",
    // designation: "one of the top 3 US   content aggregator",
  },
  {
    heading: `Throughout my years at IDS, I have found that the company exemplifies great teamwork in every situation. The organization has consistently upheld a positive work environment where you grow and learn every day. It's truly fulfilling to be a member of such a robust workplace.`,
    // avatar: avatar,
    name: "Amrita Kapoor Bedi",
    // designation: "one of the top 3 US   content aggregator",
  },
  {
    heading: `Being part of a renowned company like IDS feels good as I get the opportunity to learn new things every day. My seniors are always there to guide me, which helps me enhance my knowledge and skills.`,
    // avatar: avatar,
    name: "Pankil Verma",
    // designation: "one of the top 3 US   content aggregator",
  },

  {
    heading: `Working with a talented team while self-nurturing has impacted my life and career in all aspects. From professional growth to work-life balance, there is everything one can ask for in a corporate job.`,
    // avatar: avatar,
    name: "Shikha Arora",
    // designation: "one of the top 3 US   content aggregator",
  },
  {
    heading: `Joining IDS was an important step toward my career path. The tasks to be completed are properly planned and executed. I am thankful for all the support and guidance from the entire team.`,
    // avatar: avatar,
    name: "Nidhi Sharma",
    // designation: "one of the top 3 US   content aggregator",
  },
];

export default testimonials;
