import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Stack } from "@mui/system";
import ReCAPTCHA from "react-google-recaptcha";

const ReferredByForm = (props) => {
  return (
    <>
      <Stack ref={props.formRef}></Stack>
      <Stack
        my={"3%"}
        maxWidth={{ xs: "90%", md: "sm" }}
        mt="3%"
        mx={"auto"}
        bgcolor={"primary.white"}
      >
        <Typography
          textAlign={"center"}
          color={"primary.blue800"}
          sx={{
            typography: { xs: "displayXsSemi", md: "displaySmSemi" },
          }}
          mb={2}
        >
          Tell us more about this referral
        </Typography>

        <form onSubmit={props.handleSubmit}>
          <Stack textAlign={"left"} spacing={3}>
            <FormControl
              error={props.errors.is_worked && props.touched.is_worked}
            >
              {/* <FormControl> */}
              <FormLabel
                color="secondary"
                id="demo-row-radio-buttons-group-label"
              >
                Have you worked with this person in the past ? *
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="is_worked"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.is_worked}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio color="secondary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio color="secondary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            <FormControl
              error={
                props.errors.is_actively_looking &&
                props.touched.is_actively_looking
              }
            >
              <FormLabel
                color="secondary"
                id="demo-row-radio-buttons-group-label"
              >
                Is this person actively looking for a job? *
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="is_actively_looking"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.is_actively_looking}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio color="secondary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio color="secondary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            <FormLabel>
              Why do you think this person would be a good match for a position
              at IDS?
              <TextField
                sx={{
                  my: "4px",
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                size="small"
                name="message"
                variant="outlined"
                // label="Why do you think this person would be a good match for a position at IDS?"
                multiline
                rows={4}
                value={props.message}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.message && Boolean(props.message)}
                helperText={props.message && props.message}
              />
            </FormLabel>
          </Stack>
          <Stack direction={"column"} justifyContent="center" display={"flex"}>
            <Typography
              textAlign={"center"}
              sx={{
                pt: 4,

                color: "primary.blue700",
                typography: { xs: "displayXsSemi", md: "displaySmSemi" },
              }}
            >
              Employee Information
            </Typography>
            <Typography
              textAlign={"center"}
              sx={{
                pb: 2,
                typography: { xs: "textMdReg", md: "textLgReg" },
              }}
            >
              {" "}
              Please enter your information below
            </Typography>
          </Stack>
          <Stack
            spacing={3}
            direction={"column"}
            justifyContent="center"
            display={"flex"}
          >
            {/* <Typography
              textAlign={"center"}
              sx={{
                pt: 4,
                pb: -2,
                color: "primary.blue700",
                typography: { xs: "displayXsSemi", md: "displaySmSemi" },
              }}
            >
              Employee Information
            </Typography>
            <Typography
              textAlign={"center"}
              pt={-6}
              sx={{
                typography: { xs: "textMdReg", md: "textLgReg" },
              }}
            >
              Please enter your information below
            </Typography> */}
            <Stack
              spacing={3}
              display={"flex"}
              direction={{ xs: "column", md: "row" }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Typography my={1}>First Name *</Typography>
                <TextField
                  sx={{
                    width: { xs: "100%", md: "285px" },
                    ".MuiFormHelperText-root": {
                      typography: "textSmReg",
                      color: "#d3302f",
                    },
                  }}
                  color="secondary"
                  size="small"
                  name="first_name"
                  variant="outlined"
                  placeholder="First Name"
                  value={props.values.first_name}
                  onChange={(e) =>
                    props.setFieldValue(
                      "first_name",
                      e.target.value.replace(/[^a-z]/gi, "")
                    )
                  }
                  onBlur={props.handleBlur}
                  error={props.errors.first_name && props.touched.first_name}
                  helperText={
                    props.touched.first_name && props.errors.first_name
                  }
                />
              </Stack>
              <Stack>
                <Typography my={1}>Last Name</Typography>
                <TextField
                  sx={{
                    width: { xs: "100%", md: "285px" },
                    ".MuiFormHelperText-root": {
                      typography: "textSmReg",
                      color: "#d3302f",
                    },
                  }}
                  color="secondary"
                  size="small"
                  name="last_name"
                  variant="outlined"
                  placeholder="Last Name"
                  value={props.name}
                  onChange={(e) =>
                    props.setFieldValue(
                      "last_name",
                      e.target.value.replace(/[^a-z]/gi, "")
                    )
                  }
                  onBlur={props.handleBlur}
                  // error={props.name && Boolean(props.name)}
                  // helperText={props.name && props.name}
                />
              </Stack>
            </Stack>
            <Stack>
              <Typography my={1}>Email *</Typography>
              <TextField
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                size="small"
                name="email"
                variant="outlined"
                placeholder="Email"
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.email && props.touched.email}
                helperText={props.touched.email && props.errors.email}
              />
            </Stack>
            <Stack
              spacing={3}
              display={"flex"}
              direction={{ xs: "column", md: "row" }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Typography my={1}>Department *</Typography>
                <TextField
                  select
                  sx={{
                    width: { xs: "100%", md: "360px" },
                    ".MuiFormHelperText-root": {
                      typography: "textSmReg",
                      color: "#d3302f",
                    },
                  }}
                  color="secondary"
                  size="small"
                  name="department_id"
                  variant="outlined"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={
                    props.touched.department_id && props.errors.department_id
                  }
                  helperText={
                    props.touched.department_id && props.errors.department_id
                  }
                  value={props.values.department_id}
                >
                  <MenuItem value="" disabled selected>
                    Select Department
                  </MenuItem>

                  {props.departments.map((department) => (
                    <MenuItem key={department.id} value={department.id}>
                      {department.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack>
                <Typography my={1}>Employee code *</Typography>
                <TextField
                  sx={{
                    width: { xs: "100%", md: "200px" },
                    ".MuiFormHelperText-root": {
                      typography: "textSmReg",
                      color: "#d3302f",
                    },
                  }}
                  inputProps={{
                    maxLength: 8,
                  }}
                  color="secondary"
                  size="small"
                  placeholder="Employee code"
                  name="employee_code"
                  variant="outlined"
                  value={props.values.employee_code}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={
                    props.touched.employee_code && props.errors.employee_code
                  }
                  helperText={
                    props.touched.employee_code && props.errors.employee_code
                  }
                ></TextField>
              </Stack>
            </Stack>
            <Stack
              sx={{ width: { md: "100%" }, mx: { md: "auto" }, mb: "2rem" }}
            >
              <Stack
                display={"flex"}
                direction={{ md: "row" }}
                spacing={0}
                width={"100%"}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="disclaimer"
                      value={props.values.disclaimer}
                      checked={props.values.disclaimer === true ? true : false}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      sx={{
                        "&.Mui-checked": {
                          color: "primary.primary600",
                        },
                      }}
                    />
                  }
                />
                <Typography sx={{ width: { md: "100%" } }}>
                  I confirm my consent for IDS to process my personal
                  information to fulfill this request in compliance with IDS
                  Infotech Limited's
                  <span>
                    <a
                      style={{ paddingLeft: "0.2rem" }}
                      rel="noreferrer"
                      target="_blank"
                      href="https://idsil.com/policies/privacy"
                    >
                      Privacy Statement
                    </a>
                    .
                  </span>
                </Typography>
              </Stack>
              {props.errors.disclaimer && props.touched.disclaimer ? (
                <Typography
                  sx={{
                    color: "#d32f2f",
                    fontFamily: "Inter",
                    fontStyle: " normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    mx: "14px",
                    mt: "3px",
                    lineHeight: "20px",
                  }}
                  className="helperText"
                  textAlign={"left"}
                >
                  {props.errors.disclaimer}
                </Typography>
              ) : null}
            </Stack>

            {/* <TextField
              name="checkField"
              sx={{ display: "none" }}
              onChange={props.handleChange}
              value=""
            /> */}
            {/* // ? ******************** Recaptcha ****************************** */}
            <Stack sx={{ width: { md: "100%" }, mb: "2rem" }}>
              <Stack
                display={"flex"}
                direction={{ md: "column" }}
                spacing={0}
                width={"100%"}
              >
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                  ref={props.captchaRef}
                  name="token"
                  onBlur={props.handleBlur}
                  onChange={(value) => {
                    props.handleChange(value);
                    props.setFieldValue("token", value);
                  }}
                />
                {props.errors.token && props.touched.token ? (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontFamily: "Inter",
                      fontStyle: " normal",
                      fontWeight: 400,
                      fontSize: "14px",
                      mx: "14px",
                      mt: "3px",
                      lineHeight: "20px",
                    }}
                    className="helperText"
                    textAlign={"left"}
                  >
                    {props.errors.token}
                  </Typography>
                ) : null}
              </Stack>
            </Stack>
            {!props.isRegistering && (
              <Button
                size="small"
                variant="contain"
                type="submit"
                sx={[
                  {
                    color: "white",
                    bgcolor: "primary.blue600",
                    fontSize: "16px",
                    fontWeight: "600",
                    padding: "12px 20px",
                    lineHeight: "24px",
                  },
                  {
                    "&:hover": {
                      bgcolor: "primary.blue700",
                    },
                  },
                ]}
              >
                Submit
              </Button>
            )}
            {props.isRegistering && <CircularProgress />}
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default ReferredByForm;
