import React, { Suspense, useState } from "react";
import Banner from "../components/home/Banner";
import { Await, defer, useLoaderData } from "react-router-dom";
import useApi from "../hooks/use-api";
import List from "../components/home/List";
import Location from "../components/home/Location";
import Testimonial from "../components/home/Testimonial";
import About from "../components/home/About";
import GoToTop from "../utils/GoToTop";
import ListSkeleton from "../components/layouts/skeleton/ListSkeleton";

const Home = () => {
  const { openings } = useLoaderData();
  const [isActiveButton, setIsActiveButton] = useState("View All");
  const [filteredList, setFilteredList] = useState("");

  return (
    <>
      <Banner />
      <Suspense fallback={<ListSkeleton />}>
        <Await resolve={openings}>
          {(loadedOpenings) => (
            <List
              lists={loadedOpenings}
              isActiveButton={isActiveButton}
              setIsActiveButton={setIsActiveButton}
              filteredList={filteredList}
              setFilteredList={setFilteredList}
            />
          )}
        </Await>
      </Suspense>
      <About />
      <Location />
      <Testimonial />
      <GoToTop />
    </>
  );
};

export default Home;

// ? ***************************************************************** Openings Data ***************************************************************** */
const loadOpenings = async () => {
  try {
    const openings = await useApi.getRequest("openings", false);
    if (openings.status) {
      return openings.data;
    }

    throw new Error(openings.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ***************************************************************** export loaders ***************************************************************** */
export const loader = () => {
  return defer({
    openings: loadOpenings(),
  });
};
