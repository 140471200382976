import React from "react";
import { Stack } from "@mui/system";
import PaperContainer from "../components/layouts/PaperContainer";
import { Box, Typography } from "@mui/material";
import aboutData from "../store/AboutData";
import Div from "../components/layouts/PaperContainer";
import AboutCard from "../components/cards/AboutCard";
import Journey from "../components/about/Journey";
import img1 from "../assets/images/image 1.webp";
import img3 from "../assets/images/image 3.webp";
import img4 from "../assets/images/image 4.webp";
import img6 from "../assets/images/image 6.webp";
import img5 from "../assets/images/image 5.webp";
import JointVentures from "../components/about/JointVentures";
import GoToTop from "../utils/GoToTop";

const About = () => {
  return (
    <>
      <Stack>
        <Stack mt={4} mb={"6rem"}>
          <PaperContainer my={10}>
            <Stack maxWidth={"xl"} mx={"auto"} spacing={2}>
              <Typography
                sx={{ typography: { xs: "textSmSemi", md: "textMdSemi" } }}
                color="primary.blue600"
              >
                About IDS
              </Typography>
              <Typography
                sx={{
                  typography: { xs: "displayMdSemi", md: "displayLgSemi" },
                }}
                color="primary.gray900"
              >
                Transforming Organizations Worldwide with AI-enabled Digital
                Solutions
              </Typography>
              <Typography
                pt={2}
                sx={{
                  typography: { xs: "textMdReg", md: "textLgReg" },
                }}
              >
                Established in 1989, IDS Infotech Ltd. is built on the legacy of
                providing customized solutions to professionals and business
                houses across different industries, enabling them to optimize
                their business processes and achieve excellence and higher
                productivity.<br></br>
                <br></br>
                As a preferred supplier to Fortune 500 companies, IDS boasts a
                strong retention rate and has a long-term association with over
                90% of its clients. IDS has a highly skilled and experienced
                leadership and core team having an average association of 15+
                years.
                <a href="https://idsil.com/about.php" style={{ all: "unset" }}>
                  <Typography
                    my={2}
                    color={"primary.blue700"}
                    sx={{
                      typography: { xs: "textMdSemi", md: "textLgSemi" },
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Learn more about IDS
                  </Typography>
                </a>
              </Typography>
            </Stack>
            <Stack
              maxWidth={"xl"}
              mx={"auto"}
              flexDirection={"row"}
              display={"flex"}
              justifyContent={{ xs: "center", md: "initial" }}
              flexWrap={"wrap"}
            >
              {aboutData.map((data, index) => {
                return (
                  <AboutCard
                    key={index}
                    link={data.link}
                    title={data.title}
                    data={data.content}
                  />
                );
              })}
            </Stack>
          </PaperContainer>

          <Journey />
          <JointVentures />
          <Div>
            <Stack
              mt={"-3rem"}
              mb={"4rem"}
              display={"flex"}
              // justifyContent={"space-between"}
              alignItems="center"
              maxWidth={"lg"}
              mx={"auto"}
              flexWrap={"wrap"}
              justifyContent={{
                xs: "center",
                // sm: "left",

                xl: "center",
              }}
              // spacing={{ xs: 2, md: 5 }}
              rowGap={{ xs: 5, md: 5 }}
              columnGap={{ md: -10 }}
              sx={{ objectFit: "contain" }}
              direction={{ xs: "row" }}
            >
              <Stack
                alignItems="center"
                justifyContent={{
                  xs: "center",
                  // sm: "left",

                  xl: "center",
                }}
                sx={{ objectFit: "contain" }}
              >
                <Box component={"img"} width={"50%"} src={img1} />
              </Stack>
              <Stack
                alignItems="center"
                justifyContent={{
                  xs: "center",
                  // sm: "left",

                  xl: "center",
                }}
                sx={{ objectFit: "contain" }}
              >
                <Box component={"img"} width={"50%"} src={img3} />
              </Stack>
              <Stack
                alignItems="center"
                justifyContent={{
                  xs: "center",
                  // sm: "left",

                  xl: "center",
                }}
                sx={{ objectFit: "contain" }}
              >
                <Box component={"img"} width={"50%"} src={img4} />
              </Stack>
              <Stack
                alignItems="center"
                justifyContent={{
                  xs: "center",
                  // sm: "left",

                  xl: "center",
                }}
                sx={{ objectFit: "contain" }}
              >
                <Box component={"img"} width={"50%"} src={img5} />
              </Stack>
              <Stack
                alignItems="center"
                justifyContent={{
                  xs: "center",
                  // sm: "left",

                  xl: "center",
                }}
                sx={{ objectFit: "contain" }}
              >
                <Box component={"img"} width={"50%"} src={img6} />
              </Stack>
            </Stack>
          </Div>
        </Stack>
      </Stack>
      <GoToTop />
    </>
  );
};

export default About;
