import { Pagination, Stack } from "@mui/material";
import React from "react";

const Paginate = (props) => {
  return (
    <Stack spacing={2}>
      <Pagination
        color="primary"
        count={Math.ceil(props.count / 5)}
        // variant="outlined"
        shape="rounded"
        onChange={props.handleChange}
      />
    </Stack>
  );
};

export default Paginate;
