import React from "react";
import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import PaperContainer from "../layouts/PaperContainer";
import Map from "./Map";

const Location = () => {
  return (
    <PaperContainer mb={10} bgColor={"primary.blue800"}>
      <Grid
        spacing={2}
        container
        py={8}
        display={"flex"}
        justifyContent={"space-between"}
        direction={{ xs: "column", md: "row" }}
        maxWidth="xl"
        mx={"auto"}
      >
        <Grid item xs={12} md={4}>
          <Stack sx={{ position: "sticky", top: "8rem" }}>
            <Typography
              sx={{
                typography: { xs: "textMdSemi" },
                color: "primary.blue50",
              }}
            >
              Global Presence
            </Typography>

            <Typography
              sx={{
                color: "white",
                pb: 4,
                typography: { xs: "displayLgBold", md: "displayXlBold" },
              }}
            >
              Discover Our Footprint
            </Typography>
            <Map />
          </Stack>
        </Grid>

        <Grid py={2} item xs={12} md={8}>
          <Stack>
            <Typography
              my={1}
              mt={3}
              color={"primary.blue300"}
              sx={{ typography: { xs: "textLgSemi", sm: "displayXsBold" } }}
              maxWidth={"fit-content"}
            >
              {""}
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={6}>
                <Typography
                  my={1}
                  mt={3}
                  color={"primary.blue300"}
                  sx={{ typography: { xs: "textLgSemi", sm: "displayXsBold" } }}
                  maxWidth={"fit-content"}
                >
                  UK
                </Typography>
                <Typography
                  color={"primary.blue50"}
                  sx={{ typography: { xs: "textSmSemi", sm: "textMdSemi" } }}
                >
                  IDS Infotech (UK) Limited
                </Typography>
                <Typography
                  color={"primary.blue50"}
                  sx={{
                    typography: { xs: "textSmReg", sm: "textMdReg" },
                    opacity: "0.7",
                  }}
                  maxWidth={{ xs: "85%", md: "75%" }}
                >
                  Rear Ground Floor, Hygeia Building, 66-68 College Road,
                  Harrow, Middlesex HA1 1BE, London
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography
                  my={1}
                  mt={3}
                  color={"primary.blue300"}
                  sx={{
                    typography: { xs: "textLgSemi", sm: "displayXsBold" },
                    textTransform: "uppercase",
                  }}
                  maxWidth={"fit-content"}
                >
                  Europe
                </Typography>

                <Typography
                  color={"primary.blue50"}
                  sx={{ typography: { xs: "textSmSemi", sm: "textMdSemi" } }}
                >
                  B.V. Design-Production Link D.P.L
                </Typography>
                <Typography
                  color={"primary.blue50"}
                  sx={{
                    typography: { xs: "textSmReg", sm: "textMdReg" },
                    opacity: "0.7",
                  }}
                  maxWidth={{ xs: "85%", md: "75%" }}
                >
                  BIC 1, 5657 BX Eindhoven, Netherlands
                </Typography>
              </Grid>
            </Grid>
          </Stack>

          <Stack>
            <Typography
              my={1}
              mt={3}
              color={"primary.blue300"}
              sx={{ typography: { xs: "textLgSemi", sm: "displayXsBold" } }}
              maxWidth={"fit-content"}
            >
              USA
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={6}>
                {/* <Typography
                  color={"primary.blue50"}
                  sx={{ typography: { xs: "textSmSemi", sm: "textMdSemi" } }}
                >
                  USA, New York
                </Typography> */}
                <Typography
                  color={"primary.blue50"}
                  sx={{ typography: { xs: "textSmReg", sm: "textMdReg" } }}
                >
                  IDS America Inc.
                </Typography>
                <Typography
                  color={"primary.blue50"}
                  sx={{
                    typography: { xs: "textSmReg", sm: "textMdReg" },
                    opacity: "0.7",
                  }}
                  maxWidth={{ xs: "85%", md: "75%" }}
                >
                  501, Fifth Avenue, Suite 805, New York, NY 10017 USA
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack>
            <Typography
              my={1}
              mt={3}
              color={"primary.blue300"}
              sx={{ typography: { xs: "textLgSemi", sm: "displayXsBold" } }}
              maxWidth={"fit-content"}
            >
              INDIA
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={6}>
                <Typography
                  color={"primary.blue50"}
                  sx={{ typography: { xs: "textSmSemi", sm: "textMdSemi" } }}
                >
                  IDS Infotech Limited
                </Typography>
                <Typography
                  color={"primary.blue50"}
                  sx={{
                    typography: { xs: "textSmReg", sm: "textMdReg" },
                    opacity: "0.7",
                  }}
                  maxWidth={{ xs: "85%", md: "75%" }}
                >
                  C-138, Phase VIII, Industrial Area, Mohali, Punjab - 160071
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography
                  color={"primary.blue50"}
                  sx={{ typography: { xs: "textSmSemi", sm: "textMdSemi" } }}
                >
                  IDS Infotech Limited
                </Typography>
                <Typography
                  color={"primary.blue50"}
                  sx={{
                    typography: { xs: "textSmReg", sm: "textMdReg" },
                    opacity: "0.7",
                  }}
                  maxWidth={{ xs: "85%", md: "75%" }}
                >
                  Rajiv Gandhi Chandigarh Technology Park, Chandigarh - 160003
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography
                  color={"primary.blue50"}
                  sx={{ typography: { xs: "textSmSemi", sm: "textMdSemi" } }}
                >
                  IDS Infotech Limited
                </Typography>
                <Typography
                  color={"primary.blue50"}
                  sx={{
                    typography: { xs: "textSmReg", sm: "textMdReg" },
                    opacity: "0.7",
                  }}
                  maxWidth={{ xs: "85%", md: "75%" }}
                >
                  A-90, Sector – 2 Noida, Uttar Pradesh - 201301
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography
                  color={"primary.blue50"}
                  sx={{ typography: { xs: "textSmSemi", sm: "textMdSemi" } }}
                >
                  IDS Infotech Limited
                </Typography>
                <Typography
                  color={"primary.blue50"}
                  sx={{
                    typography: { xs: "textSmReg", sm: "textMdReg" },
                    opacity: "0.7",
                  }}
                  maxWidth={{ xs: "85%", md: "75%" }}
                >
                  Plot No. IT-C-5, Industrial Plot, Sector 67, SAS Nagar, Mohali
                  - 160062
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography
                  color={"primary.blue50"}
                  sx={{ typography: { xs: "textSmSemi", sm: "textMdSemi" } }}
                >
                  IDS Infotech Limited
                </Typography>
                <Typography
                  color={"primary.blue50"}
                  sx={{
                    typography: { xs: "textSmReg", sm: "textMdReg" },
                    opacity: "0.7",
                  }}
                  maxWidth={{ xs: "85%", md: "75%" }}
                >
                  Plot No. EL 643, Industrial Area, Phase 9, Mohali, Punjab -
                  160065
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography
                  color={"primary.blue50"}
                  sx={{ typography: { xs: "textSmSemi", sm: "textMdSemi" } }}
                >
                  IDS Infotech Limited
                </Typography>
                <Typography
                  color={"primary.blue50"}
                  sx={{
                    typography: { xs: "textSmReg", sm: "textMdReg" },
                    opacity: "0.7",
                  }}
                  maxWidth={{ xs: "85%", md: "75%" }}
                >
                  3rd Floor, H28, ARV Park, Sector 63, Noida, Uttar Pradesh -
                  201301
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          {/* <Typography
            color={"primary.blue50"}
            sx={{ typography: { xs: "textSmSemi", sm: "textMdSemi" } }}
            maxWidth={"fit-content"}
          >
            INDIA
          </Typography>
          <Grid
            spacing={2}
            mb={4}
            display={"flex"}
            container
            justifyContent={"end"}
          >
            <Grid item xs={12} md={2}>
             
            </Grid>
            <Grid item xs={12} md={2}>
              
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography
                color={"primary.blue50"}
                sx={{ typography: { xs: "textSmSemi", sm: "textMdSemi" } }}
              >
                IDS Infotech Limited
              </Typography>
              <Typography
                color={"primary.blue50"}
                sx={{ typography: { xs: "textSmReg", sm: "textMdReg" } }}
                maxWidth={"sm"}
              >
                Plot No. EL 643, Industrial Area, Phase 9, Mohali, Punjab -
                160065
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>

            </Grid>
            <Grid item xs={12} md={2}>
              <Typography
                color={"primary.blue50"}
                sx={{ typography: { xs: "textSmSemi", sm: "textMdSemi" } }}
              >
                IDS Infotech Limited
              </Typography>
              <Typography
                color={"primary.blue50"}
                sx={{ typography: { xs: "textSmReg", sm: "textMdReg" } }}
                maxWidth={"sm"}
              >
                A-90, Sector – 2 Noida, Uttar Pradesh - 201301
              </Typography>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

export default Location;
