const experiences = [
  {
    id: 1,
    duration: "0-01 Years",
  },
  {
    id: 2,
    duration: "01-03 Years",
  },
  {
    id: 3,
    duration: "03-05 Years",
  },
  {
    id: 4,
    duration: "05-08 Years",
  },
  {
    id: 5,
    duration: "08-10 Years",
  },
  {
    id: 6,
    duration: "10-15 Years",
  },
  {
    id: 7,
    duration: "15+ Years",
  },
];

export default experiences;
