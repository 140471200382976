import { Stack } from "@mui/material";
import React from "react";
import ProgressBar from "./ProgressBar";

const Uploading = (props) => {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100px",
        backgroundColor: "white",
        padding: "20px",
        my: "1rem",
        border: "2px solid",
        borderColor: "grey.grey100",
        borderRadius: "8px",
        position: "relative",
      }}
    >
      <Stack position={"absolute"} left={"8px"} right={"8px"}>
        <ProgressBar progress={props.progress} />
      </Stack>
    </Stack>
  );
};

export default Uploading;
