import React from "react";
import FormStyle from "../../assets/css/Auth.module.css";

const Input = (props) => {
  return (
    <input
      autoComplete="off"
      type={props.type}
      id={props.id}
      placeholder={props.placeholder}
      name={props.name}
      readOnly={props.readOnly}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      className={`${FormStyle.input} ${props.className}`}
      maxLength={props.maxLength}
    />
  );
};

export default Input;
