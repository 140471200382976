import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneFiled = (props) => {
  // set the desired width here

  return (
    <>
      <PhoneInput
        countryCodeEditable={false}
        containerClass={props.className}
        inputProps={{
          name: "phone",
        }}
        country={"in"}
        value={props.value}
        onChange={(value, country, e, formattedValue) => {
          props.setFieldValue("phone", value);
          props.setFieldValue("phone_formatted", formattedValue);
        }}
        onBlur={props.onBlur}
        enableSearch={true}
      />
    </>
  );
};

export default PhoneFiled;
