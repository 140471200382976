import React, { useEffect } from "react";
import earthWater from "../../assets/images/earth-water.webp";
import { Stack } from "@mui/material";
import Globe from "react-globe.gl";

function Map() {
  const globeEl = React.useRef();
  const arcsData = [1, 2, 3, 4, 5, 6].map(() => ({
    startLat: (Math.random() - 0.5) * 180,
    startLng: (Math.random() - 0.5) * 360,
    endLat: (Math.random() - 0.5) * 180,
    endLng: (Math.random() - 0.5) * 360,
    color: [["#000000"][0], ["#000000"][0]],
  }));

  useEffect(() => {
    globeEl.current.controls().enableZoom = false;
    globeEl.current.controls().autoRotate = true;
    globeEl.current.controls().autoRotateSpeed = 5;
  }, []);

  return (
    <>
      <Stack
        display={"flex"}
        maxWidth={350}
        bgcolor={"#065986"}
        overflow={"hidden"}
      >
        <Globe
          ref={globeEl}
          arcsData={arcsData}
          arcColor={[
            "rgb(0, 255, 225)",
            "rgb(0, 145, 255)",
            "rgb(0, 255, 225)",
          ]}
          globeImageUrl={earthWater}
          height={320}
          width={320}
          autoRotate={true}
          arcDashGap={0.6}
          arcDashLength={0.5}
          arcStroke={2.5}
          arcDashAnimateTime={4000 + 500}
          rendererConfig={{ preserveDrawingBuffer: true }}
          backgroundColor="#065986"
        />
      </Stack>
    </>
  );
}

export default Map;
