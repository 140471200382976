import { Container, Paper } from "@mui/material";
import React from "react";

const PaperContainer = (props) => {
  return (
    <Paper
      elevation={0}
      sx={[
        {
          "&.MuiPaper-root": {
            backgroundColor: props.bgColor,
            borderRadius: "0px",
            color: props.color,

            marginY: props.my,
            marginX: props.mx,
            paddingX: props.px,
            paddingY: props.py,
          },
        },
      ]}
    >
      <Container
        sx={[
          {
            maxWidth: { xs: "95%", md: "95%" },
          },
          { "&	.MuiContainer-root": { backgroundColor: props.bgColor } },
        ]}
      >
        {props.children}
      </Container>
    </Paper>
  );
};

export default PaperContainer;
