import React, { Fragment } from "react";
import {
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
// import DragAndDrop from "../../utils/DragAndDrop";
import experiences from "../../store/Experiences";
// import FileUploaded from "../application/FileUploaded";
// import FileUploading from "../application/FileUploading";
import UploadFile from "../UploadFile";

function ReferralForm(props) {
  console.log(props.errors);
  return (
    <Fragment>
      <Stack ref={props.formRef}></Stack>
      <Stack
        my={"3%"}
        maxWidth={{ xs: "90%", md: "sm" }}
        minWidth={{ xs: "90%", md: "initial" }}
        mt={8}
        mx={"auto"}
        bgcolor={"primary.white"}
      >
        <Stack sx={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            sx={{
              typography: { xs: "textSmSemi", md: "textMdSemi" },
              color: "primary.blue700",
            }}
          >
            {props.isShowReferre
              ? "Candidate Info"
              : "Exclusive for IDS employees"}
          </Typography>
          <Typography
            color={"primary.blue800"}
            sx={{
              typography: { xs: "displayMdSemi", md: "displayMdSemi" },
            }}
            my={2}
          >
            {/* {props.isShowReferre */}
            Referral Information
            {/* : "Complete Your Referral Form"} */}
          </Typography>
          <Stack
            sx={{ width: { xs: "100%", md: "100%" } }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: "center",
                typography: { xs: "textMdReg", md: "textMdReg" },
                mx: { xs: "0rem", md: "10rem" },
                color: "grey.grey600",
                width: { md: "50%" },
              }}
            >
              {/* 
                            {props.isShowReferre ? "Help us get to know you better by filling out this form. Your details are essential for aligning you with suitable opportunities." : "Complete this form and help us understand your potential. We're eager to learn about you. "} */}

              {props.isShowReferre
                ? "Complete this form and help us understand your potential. We're eager to learn about you."
                : "Please enter the candidate’s information"}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        my={"3%"}
        px={2}
        maxWidth={{ xs: "100%", md: "sm" }}
        minWidth={{ xs: "90%", md: "initial" }}
        mt="3%"
        mx={"auto"}
        bgcolor={"primary.white"}
      >
        {/* <Typography
          textAlign={"center"}
          color={"primary.blue800"}
          sx={{
            typography: { xs: "displayMdSemi", md: "displayMdSemi" },
          }}
          // my={2}
        >
          Referral Information
        </Typography>
        <Typography
          textAlign={"center"}
          sx={{
            textAlign: "center",
            typography: { xs: "textMdReg", md: "textMdReg" },
            mx: { xs: "0rem", md: "10rem" },
            color: "grey.grey600",
            width: { md: "50%" },
          }}
          mb={8}
        >
          Please enter the candidate’s information below
        </Typography> */}
        <form onSubmit={props.handleSubmit}>
          <Stack
            spacing={3}
            direction={"column"}
            justifyContent="center"
            display={"flex"}
          >
            <Stack
              spacing={1}
              display={"flex"}
              direction={{ xs: "column", md: "row" }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Typography my={1}>First Name *</Typography>
                <TextField
                  sx={{
                    width: { xs: "100%", md: "285px" },
                    "&.Mui-focused": {
                      backgroundColor: "primary.white",
                      border: "1px solid ",
                      borderColor: "primary.blue600",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    },
                    ".MuiFormHelperText-root": {
                      typography: "textSmReg",
                      color: "#d3302f",
                    },
                  }}
                  color="secondary"
                  size="small"
                  name="first_name"
                  placeholder="First name"
                  variant="outlined"
                  value={props.values.first_name}
                  onChange={(e) =>
                    props.setFieldValue(
                      "first_name",
                      e.target.value.replace(/[^a-z]/gi, "")
                    )
                  }
                  onBlur={props.handleBlur}
                  error={props.errors.first_name && props.touched.first_name}
                  helperText={
                    props.touched.first_name && props.errors.first_name
                  }
                />
              </Stack>
              <Stack>
                <Typography my={1}>Last Name</Typography>
                <TextField
                  sx={{
                    width: { xs: "100%", md: "285px" },
                    ".MuiFormHelperText-root": {
                      typography: "textSmReg",
                      color: "#d3302f",
                    },
                    "&.Mui-focused": {
                      backgroundColor: "primary.white",
                      border: "1px solid ",
                      borderColor: "primary.blue600",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    },
                  }}
                  color="secondary"
                  size="small"
                  name="last_name"
                  variant="outlined"
                  placeholder="Last Name"
                  value={props.values.last_name}
                  onChange={(e) =>
                    props.setFieldValue(
                      "last_name",
                      e.target.value.replace(/[^a-z]/gi, "")
                    )
                  }
                  onBlur={props.handleBlur}
                  // error={props.name && Boolean(props.name)}
                  // helperText={props.name && props.name}
                />
              </Stack>
            </Stack>
            <Stack>
              <Typography my={1}>Email *</Typography>
              <TextField
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                size="small"
                name="email"
                variant="outlined"
                placeholder="email@email.com"
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.email && props.touched.email}
                helperText={props.touched.email && props.errors.email}
              />
            </Stack>
            <Stack>
              <Typography my={1}>Position referred for</Typography>
              <TextField
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                InputProps={{
                  readOnly: true,
                }}
                size="small"
                name="position"
                variant="outlined"
                value={props.title}
              />
            </Stack>
            <Stack>
              <Typography my={1}>Experience *</Typography>
              <TextField
                select
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                size="small"
                name="experience"
                variant="outlined"
                // placeholder="Select Experience"
                onBlur={props.handleBlur}
                error={
                  props.errors.experience &&
                  Boolean(props.errors.experience) &&
                  props.touched.experience
                }
                helperText={props.touched.experience && props.errors.experience}
                value={props.values.experience}
                onChange={props.handleChange}
              >
                <MenuItem value="" disabled>
                  Select Experience
                </MenuItem>
                {experiences.map((experience) => (
                  <MenuItem key={experience.id} value={experience.duration}>
                    {experience.duration}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack pb={8}>
              <UploadFile
                title={"Resume"}
                name={"resume"}
                folderPath="applications/documents"
                setFieldValue={props.setFieldValue}
                handleBlur={props.handleBlur}
                setFieldTouched={props.setFieldTouched}
                values={{ resume: props.values.resume }}
                accept=".pdf, .docx, .doc"
                numberOfFiles={3}
                isMultiple={true}
                errorMessage={props.errors?.resume}
                errorStatus={
                  props.errors?.resume !== undefined &&
                  props.errors?.resume !== undefined
                    ? true
                    : false
                }
              />
              {/* <DragAndDrop
                errors={props.errors.file}
                onFileChangeHandler={props.onFileChangeHandler}
                isUploading={props.isUploading}
              />
              {props.isUploaded && (
                <>
                  <FileUploaded
                    fileName={props.fileName}
                    fileSize={props.fileSize}
                    file={props.values.resume}
                    percentage={props.percentage}
                    deleteFileName="referrals"
                    deleteUploadFile={props.deleteUploadFile}
                  />
                </>
              )}
              {props.isUploading && (
                <>
                  <FileUploading progress={props.percentage} />
                </>
              )} */}
            </Stack>

            {!props.isSubmitting && (
              <Button
                size="small"
                variant="contain"
                type="submit"
                sx={[
                  {
                    color: "white",
                    bgcolor: "primary.blue600",
                    fontSize: "16px",
                    fontWeight: "600",
                    padding: "12px 20px",
                    lineHeight: "24px",
                  },
                  {
                    "&:hover": {
                      bgcolor: "primary.blue700",
                    },
                  },
                ]}
              >
                Next
              </Button>
            )}
            {props.isSubmitting && <CircularProgress />}
          </Stack>
        </form>
      </Stack>
    </Fragment>
  );
}

export default ReferralForm;
