import React from "react";
import { Stack, Typography } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import PaperContainer from "../layouts/PaperContainer";

const Heading = (props) => {
  return (
    <PaperContainer my={10}>
      <Stack
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        mb={1}
      >
        <Typography
          sx={{
            textAlign: "center",
            typography: { xs: "textLgSemi" },
            color: "primary.blue700",
          }}
        >
          {props.opening.job.title}
        </Typography>
        <Typography
          my={2}
          sx={{
            textAlign: "center",
            typography: { xs: "displayMdSemi", sm: "displayMdSemi" },
            color: "primary.blue800",
          }}
        >
          {props.opening.title}
        </Typography>
        <Stack
          direction={"row"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Stack display={"flex"} mt={2} spacing={2} direction="row">
            <Typography
              sx={{
                typography: { xs: "textSmReg", sm: "textLgReg" },
                color: "primary.gray600",
              }}
              alignItems={"center"}
              display={"flex"}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={"center"}
            >
              <LocationOnOutlinedIcon sx={{ mr: "2px", p: "2px" }} />{" "}
              {props.opening.work_mode}
            </Typography>
            <Typography
              sx={{
                typography: { xs: "textSmReg", sm: "textLgReg" },
                color: "primary.gray600",
              }}
              flexDirection={{ xs: "column", md: "row" }}
              alignItems={"center"}
              display={"flex"}
              justifyContent={"center"}
            >
              <AccessTimeOutlinedIcon sx={{ mx: "4px", p: "2px" }} />{" "}
              {props.opening.type}
            </Typography>
            <Typography
              sx={{
                typography: { xs: "textSmReg", sm: "textLgReg" },
                color: "primary.gray600",
              }}
              flexDirection={{ xs: "column", md: "row" }}
              alignItems={"center"}
              display={"flex"}
              justifyContent={"center"}
            >
              <WorkHistoryOutlinedIcon sx={{ mx: "4px", p: "2px" }} />{" "}
              {props.opening.experience}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </PaperContainer>
  );
};

export default Heading;
