import React, { useEffect, useState } from "react";
import {
  useActionData,
  useNavigate,
  useNavigation,
  useOutletContext,
  useParams,
  useSubmit,
} from "react-router-dom";
import Toast from "../utils/Toast";
import { useFormik } from "formik";
import useApi from "../hooks/use-api";
import axios from "axios";
import { referSchema } from "../schema";
import ReferralForm from "../components/form/ReferralForm";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  applied_for: "",
  experience: "",
  file: "",
  resume: "",
  profile_url: "",
  employee_code: "",
  is_worked: "",
  is_actively_looking: "",
  department_id: "",
  referral_id: "",
  message: "",
};

function Referral() {
  const params = useParams();
  const { opening } = useOutletContext();
  const submit = useSubmit();
  const [percentage, setPercentage] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [fileError, setFileError] = useState("");
  const [isAlreadyApplied, setIsAlreadyApplied] = useState(false);
  const actionData = useActionData();
  const navigation = useNavigation();
  const navigate = useNavigate();
  const isSubmitting = navigation.state === "submitting";

  // ? ****************************** Size of the uploaded file **************************** */
  const formatSizeUnits = (bytes) => {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + " GB";
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + " MB";
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes > 1) {
      bytes = bytes + " bytes";
    } else if (bytes === 1) {
      bytes = bytes + " byte";
    } else {
      bytes = "0 bytes";
    }
    return bytes;
  };

  // ? ****************************** file change **************************** */
  const onFileChangeHandler = async (event) => {
    if (Array.from(event.target.files).length > 3) {
      event.preventDefault();
      alert(`Cannot upload files more than ${3}`);
      return;
    }
    setFieldValue("file", event.target.files[0]);
    const formData = new FormData();
    formData.append("documents", event.target.files[0]);
    setIsUploading(true);
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        setPercentage(Math.floor((loaded * 100) / total));
      },
      headers: {
        "x-api-key": process.env.REACT_APP_FILES_API_KEY,
      },
    };
    const fileUploadToServer = await axios.post(
      `${process.env.REACT_APP_API_URL}files?folderPath=referrals&name=documents&isMultiple=true&length=3`,
      formData,
      options
    );
    if (fileUploadToServer.data.status) {
      setIsUploading(false);
      setIsUploaded(true);
      setFileName(fileUploadToServer.data.data[0].name);
      setFileSize(formatSizeUnits(fileUploadToServer.data.data[0].size));
      return setFieldValue("resume", fileUploadToServer.data.data[0].file);
    }
    return setIsUploading(false);
  };

  // ? ****************************** delete file **************************** */
  const deleteUploadFile = async (event, file, name) => {
    const result = await useApi.deleteRequest(
      `files/${file}?folderPath=referrals`,
      false,
      "REACT_APP_FILES_API_KEY"
    );
    if (result.status) {
      setFileError("");
      setIsUploading(false);
      setIsUploaded(false);
      errors.file = "";
      touched.file = false;
      return touched.file;
    }
    setIsAlreadyApplied(true);
    return setFileError(result.message);
  };

  // ? ****************************** Submit the form **************************** */
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: referSchema,
    onSubmit: async (values) => {
      return submit(values, { method: "POST" });
    },
  });

  useEffect(() => {
    if (actionData?.status) {
      resetForm();
      // Toast.fire({ icon: "success", title: actionData.message });
      navigate(`/applications/${params.id}/referred-by`, {
        state: { ...actionData.data },
      });
      return;
    }

    if (actionData?.status === false) {
      Toast.fire({ icon: "error", title: actionData.message });
      return;
    }
  }, [actionData, resetForm, navigate, params.id]);

  return (
    <ReferralForm
      opening={opening}
      title={opening.title}
      values={values}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      fileName={fileName}
      fileSize={fileSize}
      errors={errors}
      touched={touched}
      percentage={percentage}
      isUploading={isUploading}
      isUploaded={isUploaded}
      fileError={fileError}
      isAlreadyApplied={isAlreadyApplied}
      isSubmitting={isSubmitting}
      onFileChangeHandler={onFileChangeHandler}
      deleteUploadFile={deleteUploadFile}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
    />
  );
}

export default Referral;

// ? ***************************************************************** submit form ***************************************************************** */
export const action = async ({ request, params }) => {
  try {
    const id = params.id;
    const data = await request.formData();
    const formData = {
      first_name: data.get("first_name"),
      last_name: data.get("last_name"),
      email: data.get("email"),
      applied_for: id,
      experience: data.get("experience"),
      resume: data.get("resume"),
    };
    const result = await useApi.postRequest("referrals", false, formData);
    return result;
  } catch (error) {
    return Toast({ icon: "error", title: error.message });
  }
};
