import React from "react";
import { Stack, Typography } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import CardStyle from "../../assets/css/Card.module.css";

const ListItem = (props) => {
  const { list } = props;
  return (
    <Stack
      maxWidth={"md"}
      width={{ xs: "xs", sm: "sm", md: "md" }}
      my={2}
      className="ease-transition"
      p={3}
      sx={[
        {
          cursor: "pointer",
          borderRadius: "1rem",
          border: "1px solid #EAECF0",
        },
        {
          "&:hover": {
            scale: "1.009",

            border: "1px solid var(--gray-200,#0086C9)",
            boxShadow: "0px 10px 28px -12px rgba(16, 24, 40, 0.18)",
          },
        },
      ]}
    >
      <Stack
        display={"flex"}
        justifyContent={"space-between"}
        direction={"row"}
        mb={1}
      >
        <Typography
          sx={{
            typography: { xs: "textMdSemi" },
            color: "primary.blue700",
          }}
        >
          {list.job.title}
        </Typography>
        <Typography
          sx={{
            typography: { xs: "textMdSemi" },
            color: "primary.blue700",
          }}
          display={{ xs: "none", sm: "flex" }}
          justifyContent={"center"}
        >
          View job <ArrowOutwardIcon sx={{ mx: "2px", pb: "5px" }} />
        </Typography>
      </Stack>
      <Typography
        mb={2}
        sx={{ typography: { xs: "textLgSemi", sm: "textXlSemi" } }}
      >
        {list.title}
      </Typography>
      <Typography
        color={"primary.gray600"}
        maxWidth="sm"
        sx={{ typography: { xs: "textMdLeg", sm: "textLgReg" } }}
        className={CardStyle.description_class}
      >
        {list.description}
      </Typography>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Stack
          display={"flex"}
          mt={2}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={2}
          direction="row"
        >
          <Typography
            sx={{
              typography: { xs: "textXsReg", sm: "textMdReg" },
              color: "primary.gray600",
            }}
            flexDirection={{ xs: "column", md: "row" }}
            display={"flex"}
            textAlign={"center"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <LocationOnOutlinedIcon
              sx={{
                mx: { xs: "4px", md: "initial" },
                p: "2px",
                width: { xs: "15px", md: "20px" },
                height: { xs: "15px", md: "20px" },
              }}
            />{" "}
            {list.work_mode}
          </Typography>
          <Typography
            sx={{
              typography: { xs: "textXsReg", sm: "textMdReg" },
              color: "primary.gray600",
            }}
            flexDirection={{ xs: "column", md: "row" }}
            display={"flex"}
            textAlign={"center"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <AccessTimeOutlinedIcon
              sx={{
                mx: "4px",
                p: "2px",
                width: { xs: "15px", md: "20px" },
                height: { xs: "15px", md: "20px" },
              }}
            />{" "}
            {list.type}
          </Typography>
          <Typography
            sx={{
              typography: { xs: "textXsReg", sm: "textMdReg" },
              color: "primary.gray600",
            }}
            flexDirection={{ xs: "column", md: "row" }}
            display={"flex"}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <WorkHistoryOutlinedIcon
              sx={{
                mx: "4px",
                p: "2px",
                width: { xs: "15px", md: "20px" },
                height: { xs: "15px", md: "20px" },
              }}
            />{" "}
            {list.experience}
          </Typography>
        </Stack>
        {/* <Stack>
          <Typography
            sx={{
              typography: { xs: "textXsReg", sm: "textMdReg" },
              color: "primary.gray600",
            }}
              flexDirection={{ xs: "column", md: "row" }}
            display={"flex"}
            justifyContent={"center"}
          >
            #{list.id}
          </Typography>
        </Stack> */}
      </Stack>
    </Stack>
  );
};

export default ListItem;
