import React from "react";

const InputLink = (props) => {
  return (
    <>
      <input
        type="text"
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) =>
          props.setFieldValue(
            props.name,
            e.target.value.replace(/[<>""\s]/g, "")
          )
        }
        onBlur={props.onBlur}
        className={props.className}
      />
    </>
  );
};

export default InputLink;
