import React from "react";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  SwipeableDrawer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";

const NavSwipeDrawer = (props) => {
  const navigate = useNavigate();
  return (
    <SwipeableDrawer
      PaperProps={{
        sx: { backgroundColor: "grey.main", width: "100%" },
      }}
      open={props.isOpen}
      onOpen={() => props.setIsOpen(true)}
      onClose={() => props.setIsOpen(false)}
      anchor="right"
    >
      <Stack mr={3} textAlign={"end"}>
        <div>
          <IconButton onClick={() => props.setIsOpen(!props.isOpen)}>
            <CloseIcon />
          </IconButton>
        </div>
      </Stack>
      <Divider />
      <List
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <ListItem
          onClick={() => {
            navigate("/about-us");
            props.setIsOpen(!props.isOpen);
          }}
          sx={props.buttonStyle}
        >
          About Us
        </ListItem>
        <ListItem
          onClick={() => {
            window.location = "https://idsil.com/";
            props.setIsOpen(!props.isOpen);
          }}
          sx={props.buttonStyle}
        >
          Back to Corporate
        </ListItem>
        <ListItem
          onClick={() => {
            window.location = "https://idsil.com/contact-us/";
            props.setIsOpen(!props.isOpen);
          }}
          sx={props.buttonStyle}
        >
          Get in Touch
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
};

export default NavSwipeDrawer;
