import { Box, LinearProgress, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const ProgressBar = (props) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            variant="determinate"
            value={props.progress}
            sx={{
              height: "8px",
              borderRadius: "8px",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "primary.primary600",
                borderRadius: "8px",
              },
            }}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">
            {`${Math.round(props.progress)}%`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default ProgressBar;
