import React from "react";
import useApi from "../hooks/use-api";
import { useState } from "react";
import Toast from "../utils/Toast";
import { useEffect } from "react";
import { FormSchema } from "../schema";
import { useFormik } from "formik";
import axios from "axios";
import {
  useActionData,
  useNavigate,
  useNavigation,
  useOutletContext,
  useRouteLoaderData,
  useSubmit,
} from "react-router-dom";
import { useRef } from "react";
import Response from "../utils/Response";
import Form from "../components/application/Form";
import FormHeading from "../components/application/FormHeading";
import PaperContainer from "../components/layouts/PaperContainer";
import { Stack } from "@mui/material";

const initialFileUploadingState = {
  documents: {
    isUploading: false,
    isUploaded: true,
    isError: false,
    errorMsg: "",
    data: [],
  },
  certificates: {
    isUploading: false,
    isUploaded: false,
    isError: false,
    errorMsg: "",
    data: [],
  },
  awards: {
    isUploading: false,
    isUploaded: false,
    isError: false,
    errorMsg: "",
    data: [],
  },
};

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  country_id: "",
  qualification: "",
  passing_year: "",
  expected_ctc: "",
  notice_period: "",
  current_location: "",
  experience: "",
  applying_for: "",
  job_id: "",
  job_title: "",
  opening_id: "",
  department: "",
  is_recent_interview: "",
  has_history: "",
  emp_code: "",
  linkedin_url: "",
  documents: "",
  certificates: [],
  awards: [],
  checked: false,
  token: "",
};

const ApplyNow = () => {
  const { jobs, countries, cities } = useRouteLoaderData("opening");
  const { opening } = useOutletContext();
  const submit = useSubmit();
  const actionData = useActionData();
  const responseRef = useRef(null);
  const captchaRef = useRef(null);
  const uploadRef = useRef(null);
  const [percentage, setPercentage] = useState(0);
  const [isDrag, setIsDrag] = useState(false);
  const [files, setFiles] = useState(initialFileUploadingState);
  const [isDeleteUpload, setIsDeleteUpload] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    country: "",
    experience: "",
    applying_for: "",
    preferred_location: "",
  });
  const [isResponse, setIsResponse] = useState({
    status: false,
    type: "",
    message: "",
    subText: "",
    buttonText: "",
  });

  const navigation = useNavigation();
  const navigate = useNavigate();
  const isSubmitting = navigation.state === "submitting";

  // ? ****************************** Handle drag file **************************** */
  const onHandleDrag = (event) => {
    event.preventDefault();
    setIsDrag(true);
  };

  // ? ****************************** Handle drag file **************************** */
  const onHandleDrop = (event, name) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDrag(false);
    uploadAllFiles(event.dataTransfer.files, name);
  };

  // ? ****************************** Size of the uploaded file **************************** */
  const formatSizeUnits = (bytes) => {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + " GB";
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + " MB";
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes > 1) {
      bytes = bytes + " bytes";
    } else if (bytes === 1) {
      bytes = bytes + " byte";
    } else {
      bytes = "0 bytes";
    }
    return bytes;
  };

  // ? ****************************** Upload the files **************************** */
  const uploadAllFiles = async (files, name) => {
    try {
      if (files.length === 0) {
        return (files = null);
      }
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(name, files[i]);
      }
      setFiles((prevFiles) => ({
        ...prevFiles,
        [name]: {
          isUploading: true,
          isUploaded: false,
          isError: false,
          errorMsg: "",
          data: [...prevFiles[name].data],
        },
      }));
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          setPercentage(Math.floor((loaded * 100) / total));
        },
        headers: {
          "x-api-key": process.env.REACT_APP_FILES_API_KEY,
        },
      };
      const uploadFile = await axios.post(
        `${process.env.REACT_APP_API_URL}files?folderPath=applications/${name}&name=${name}&isMultiple=true&length=3`,
        formData,
        options
      );
      // uploadRef.current.value = "";
      if (uploadFile.data.status) {
        const uploadedAllFiles = uploadFile.data.data.map(
          (uploadedFiles, index) => {
            return {
              name: uploadedFiles.name,
              file: uploadedFiles.file,
              size: formatSizeUnits(uploadedFiles.size),
            };
          }
        );
        setFiles((prevFiles) => ({
          ...prevFiles,
          [name]: {
            isUploading: false,
            isUploaded: true,
            isError: false,
            errorMsg: "",
            data: prevFiles[name].data.concat(uploadedAllFiles),
          },
        }));
        uploadRef.current.value = "";
        return setFieldValue(
          name,
          values[name].concat(
            uploadFile.data.data.map((uploadedFile) => uploadedFile.file)
          )
        );
      }

      setFiles((prevFiles) => ({
        ...prevFiles,
        [name]: {
          isUploading: false,
          isUploaded: false,
          isError: false,
          errorMsg: "",
          data: [...prevFiles[name].data],
        },
      }));
      throw new Error(uploadFile.data.message);
    } catch (error) {
      return Toast.fire({ icon: "error", title: error.message });
    }
  };

  // ? ****************************** Upload the files **************************** */
  const deleteUploadFile = async (event, file, name) => {
    try {
      setIsDeleteUpload(true);
      const deleteUploadFile = await useApi.deleteRequest(
        `files/${file}?folderPath=applications/${name}`,
        false,
        "REACT_APP_FILES_API_KEY"
      );
      setIsDeleteUpload(false);
      if (deleteUploadFile.status) {
        const restFiles = files[name].data.filter(
          (deletingFile) => deletingFile.file !== file
        );
        setFiles((prevFiles) => ({
          ...prevFiles,
          [name]: {
            isUploading: false,
            isUploaded: files[name].data.length === 0 ? false : true,
            isError: false,
            errorMsg: "",
            data: restFiles,
          },
        }));
        return (values.documents = restFiles);
      }

      throw new Error(deleteUploadFile.data.message);
    } catch (error) {
      console.log(error.message);
    }
  };

  // ? ****************************** Submit Form Handler **************************** */
  const onSubmitApplicationHandler = (event) => {
    event.preventDefault();
    handleSubmit();
  };

  // ? ****************************** Formik used **************************** */
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    validateField,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: FormSchema,
    // ? ****************************** Submit form inside the formik **************************** */
    onSubmit: async (values, action) => {
      return submit(values, { method: "POST" });
    },
  });

  useEffect(() => {
    if (actionData?.status) {
      resetForm();
      // captchaRef.current.reset();
      setFiles(initialFileUploadingState);
      setIsResponse({
        status: true,
        type: "success",
        message: "Application has been submitted successfully.",
        subText:
          "Thank you for showing interest in our products/services. We will be in touch soon.",
        buttonText: "Back",
      });
      setTimeout(() => {
        responseRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
      return;
    }

    if (actionData?.status === false) {
      Toast.fire({ icon: "error", title: actionData.message });
      return;
    }

    // return navigate("/");
    // ? applying_for contains job id (in the backend)
    setFieldValue("applying_for", opening.job?.title);
    setFieldValue("job_id", opening.job?.id);
    // ? job_title is the opening id (in the backend)
    setFieldValue("job_title", opening?.title);
    setFieldValue("opening_id", opening?.id);
  }, [actionData, captchaRef, resetForm, navigate, opening, setFieldValue]);

  const [isValid, setIsValid] = useState(false);
  const handleNumberChange = (value, data) => {
    // Perform custom validation here
    const regex = /^\+?[1-9]\d{1,14}$/; // Regex pattern to match phone number
    setIsValid(value === "" || regex.test(value));
  };

  const handleNumberBlur = (value, data) => {
    // Perform custom validation here
    const regex = /^\+?[1-9]\d{1,14}$/; // Regex pattern to match phone number
    setIsValid(value === "" || regex.test(value)); // Check if phone number matches pattern or is empty
  };

  return (
    <>
      <Stack backgroundColor={"grey.grey100"}>
        {!isResponse.status && (
          <PaperContainer my={8} bgColor={"primary.grey100"}>
            <Stack
              sx={{ maxWidth: { md: "70%" }, mx: { md: "auto" } }}
              display={"flex"}
              justifyContent={"centre"}
              alignItems={"center"}
            >
              <FormHeading />
              <Form
                onSubmitApplicationHandler={onSubmitApplicationHandler}
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleNumberBlur={handleNumberBlur}
                handleNumberChange={handleNumberChange}
                validateField={validateField}
                isValid={isValid}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                jobs={jobs}
                countries={countries}
                cities={cities}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                onHandleDrag={onHandleDrag}
                onHandleDrop={onHandleDrop}
                setIsDrag={setIsDrag}
                isDrag={isDrag}
                uploadAllFiles={uploadAllFiles}
                percentage={percentage}
                deleteUploadFile={deleteUploadFile}
                files={files}
                isDeleteUpload={isDeleteUpload}
                isSubmitting={isSubmitting}
                resultRef={responseRef}
                captchaRef={captchaRef}
                uploadRef={uploadRef}
              />
            </Stack>
          </PaperContainer>
        )}
        {isResponse.status && (
          <Response
            response={isResponse}
            setIsResponse={setIsResponse}
            ref={responseRef}
          />
        )}
      </Stack>
    </>
  );
};

export default ApplyNow;

// ? ***************************************************************** submit form ***************************************************************** */
export const action = async ({ request }) => {
  try {
    const data = await request.formData();
    const formData = {
      full_name: `${data.get("first_name")} ${data.get("last_name")}`,
      phone: data.get("phone_formatted"),
      email: data.get("email"),
      country_id: data.get("country_id"),
      experience: data.get("experience"),
      job_id: data.get("job_id"),
      opening_id: data.get("opening_id"),
      qualifications: data.get("qualification"),
      passing_year: data.get("passing_year"),
      previous_organizations: data.get("previous_organizations"),
      current_ctc: data.get("current_ctc"),
      expected_ctc: data.get("expected_ctc"),
      reason: data.get("reason"),
      notice_period: data.get("notice_period"),
      current_location: data.get("current_location"),
      preferred_location: data.get("preferred_location"),
      is_recent_interview: data.get("is_recent_interview"),
      has_history: data.get("has_history"),
      department_name: data.get("organization_name"),
      employee_code: data.get("emp_code"),
      documents: data.get("documents"),
      certificates: data.get("certificates"),
      awards: data.get("awards"),
      linkedin: data.get("linkedin_url"),
      portfolio: data.get("portfolio_link"),
      projects: data.get("published_projects"),
      message: data.get("message"),
      checkField: data.get("checkField"),
    };
    const result = await useApi.postRequest(
      "applications",
      false,
      formData,
      "REACT_APP_API_APPLICATION_KEY"
    );
    return result;
  } catch (error) {
    console.log(error.message);
  }
};
