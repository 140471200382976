import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import useApi from "../hooks/use-api";
import Toast from "../utils/Toast";
import { useFormik } from "formik";
import * as Yup from "yup";
const initialValues = { email: "", checkField: "" };
const schema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter your valid email")
    .required("Please enter your valid email"),
});

const Subscriber = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  // ? ****************************** Formik used **************************** */
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    // ? ****************************** Submit form inside the formik **************************** */
    onSubmit: async (values) => {
      try {
        const formData = { ...values, type: props.type };
        setIsLoading(true);
        const result = await useApi.postRequest(
          "subscribers",
          false,
          formData,
          "REACT_APP_API_KEY_SUBSCRIBER"
        );
        setIsLoading(false);
        if (result.status) {
          resetForm();
          return Toast.fire({ icon: "success", title: result.message });
        }
        throw new Error(result.message);
      } catch (error) {
        return Toast.fire({ icon: "error", title: error.message });
      }
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Stack item="true">
        <Stack
          direction={{ md: "row", sm: "column" }}
          spacing={{ xs: 2, sm: 2, md: 4 }}
        >
          <TextField
            size="small"
            placeholder="Enter your email"
            id="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email}
            helperText={touched.email && errors.email}
            InputProps={{
              sx: {
                width: { md: props.inputWidth },
                // width: props.inputWidth,
                padding: "4px",
                "&.Mui-focused": {
                  backgroundColor: "primary.white",
                  border: "1px solid ",
                  borderColor: "primary.blue600",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                },
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              },
            }}
          />
          <input
            type="text"
            name="checkField"
            value={values.checkField}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ display: "none" }}
          />
          <Stack sx={{ m: 1, position: "relative" }}>
            <Button
              type="submit"
              variant="contained"
              py={1}
              sx={[
                {
                  color: "white",
                  bgcolor: "primary.blue600",
                  fontSize: "16px",
                  fontWeight: "600",
                  padding: "12px 20px",
                  lineHeight: "24px",
                },
                {
                  "&:hover": {
                    bgcolor: "primary.blue700",
                  },
                },
              ]}
              disableElevation
              disabled={isLoading}
            >
              Subscribe
            </Button>
            {isLoading && (
              <CircularProgress
                color="secondary"
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Stack>
        </Stack>
        {/* <Typography>
          {errors.email && touched.email ? (
            <p className={FormStyle.error_msg}>{errors.email}</p>
          ) : null}
        </Typography> */}
      </Stack>
    </form>
  );
};

export default Subscriber;
