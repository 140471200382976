import { createBrowserRouter } from "react-router-dom";
import Layout from "../components/layouts/Layout";
import Error from "../pages/Error";
import Home, { loader as openingsLoader } from "../pages/Home";
import Application, { loader as openingLoader } from "../pages/Application";
import Overview from "../pages/Overview";
import Referral, { action as referralAction } from "../pages/Referral";
import ReferredBy, { action as referredByAction } from "../pages/ReferredBy";
import ApplyNow, { action as applyNowAction } from "../pages/ApplyNow";
import About from "../pages/About";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Home />,
        loader: openingsLoader,
      },
      {
        path: "/applications",
        children: [
          {
            path: ":id",
            element: <Application />,
            id: "opening",
            loader: openingLoader,
            children: [
              {
                index: true,
                element: <Overview />,
              },
              {
                path: "referral",
                element: <Referral />,
                action: referralAction,
              },
              {
                path: "referred-by",
                element: <ReferredBy />,
                action: referredByAction,
              },
              {
                path: "apply-now",
                element: <ApplyNow />,
                action: applyNowAction,
              },
            ],
          },
        ],
      },
      {
        path: "/about-us",
        element: <About />,
      },
    ],
  },
]);

export default routes;
