import React from "react";
import Select from "react-select";
import PhoneFiled from "./PhoneField";
import ReCAPTCHA from "react-google-recaptcha";
import "react-phone-input-2/lib/bootstrap.css";
import {
  Button,
  Divider,
  FormControlLabel,
  RadioGroup,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Label from "./Label";
import CustomInputField from "./Input";
import FormStyling from "../../assets/css/Auth.module.css";
import Radio from "@mui/material/Radio";
import FormStyle from "../../assets/css/Auth.module.css";
// import FileUploadComponent from "./FileUploadComponent";
import { JobExperience, preferredLocations } from "../../store/ExperienceJob";
import Checkbox from "@mui/material/Checkbox";
// import FileUploading from "./FileUploading";
// import FileUploaded from "./FileUploaded";
import InputLink from "./InputLink";
import UploadFile from "../UploadFile";
// import ErrorFileUploadComponent from "./ErrorFileUploadComponent";

const Form = (props) => {
  return (
    <Stack
      sx={{ width: { xs: "100%" }, height: "100%" }}
      py={8}
      backgroundColor={"grey.grey100"}
    >
      <form onSubmit={props.onSubmitApplicationHandler}>
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Stack
            display={"flex"}
            direction={{ md: "row" }}
            width={"100%"}
            spacing={2}
          >
            <Stack
              item
              sx={{
                width: { xs: "100%", sm: "100%", md: "100%" },
                mb: { xs: "1.5rem", sm: "1.5rem", md: "0rem" },
              }}
            >
              <Label name="First Name *" />
              <CustomInputField
                maxLength={30}
                type="text"
                id="first_name"
                name="first_name"
                value={props.values.first_name}
                onBlur={props.handleBlur}
                onChange={(e) =>
                  props.setFieldValue(
                    "first_name",
                    e.target.value.replace(/[^a-z]/gi, "")
                  )
                }
                placeholder="First Name"
                className={
                  props.errors.first_name && props.touched.first_name
                    ? FormStyle.error
                    : ""
                }
              />

              {props.errors.first_name && props.touched.first_name ? (
                <p className={FormStyle.error_msg}>{props.errors.first_name}</p>
              ) : null}
            </Stack>
            <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              <Label name="Last Name" />
              <CustomInputField
                maxLength={30}
                type="text"
                id="last_name"
                name="last_name"
                value={props.values.last_name}
                onBlur={props.handleBlur}
                onChange={(e) =>
                  props.setFieldValue(
                    "last_name",
                    e.target.value.replace(/[^a-z]/gi, "")
                  )
                }
                placeholder="Last Name"
                className={
                  props.errors.last_name && props.touched.last_name
                    ? FormStyle.error
                    : ""
                }
              />
              {props.errors.last_name && props.touched.last_name ? (
                <p className={FormStyle.error_msg}>{props.errors.last_name}</p>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
        {/* phone_number */}
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Contact Number *" />
          <Stack display={"flex"} direction="row" width={"100%"} spacing={2}>
            <Stack sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              <PhoneFiled
                className={
                  props.errors.phone && props.touched.phone
                    ? FormStyle.phoneError
                    : FormStyle.default
                }
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.phone}
                setFieldValue={props.setFieldValue}
              />
              {props.errors.phone && props.touched.phone ? (
                <p className={FormStyle.error_msg}>{props.errors.phone}</p>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
        {/* Email */}
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Email *" />
          <Stack display={"flex"} direction="row" width={"100%"} spacing={2}>
            <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              <CustomInputField
                type="text"
                id="email"
                name="email"
                value={props.values.email}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                placeholder="Email"
                className={
                  props.errors.email && props.touched.email
                    ? FormStyle.error
                    : ""
                }
              />
              {props.errors.email && props.touched.email ? (
                <p className={FormStyle.error_msg}>{props.errors.email}</p>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
        {/* country_name */}
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Country *" />
          <Stack display={"flex"} direction="row" width={"100%"} spacing={2}>
            <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              <Select
                id="country_id"
                inputId="country_id"
                placeholder="Select Country"
                value={props.selectedOption.country}
                onChange={(selectedOption) => {
                  props.setSelectedOption({ country: selectedOption });
                  props.setFieldValue("country_id", selectedOption.value);
                }}
                onBlur={props.handleBlur}
                isSearchable={true}
                options={props.countries.map((country) => ({
                  value: country.id,
                  label: country.name,
                }))}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    border:
                      props.errors.country_id && props.touched.country_id
                        ? "2px solid red"
                        : "1px solid rgb(229 231 235)",
                    borderRadius: "8px",
                    padding: "0.1rem",
                    "&:hover": {
                      border:
                        props.errors.country_id && props.touched.country_id
                          ? "2px solid red"
                          : "1px solid rgb(14 165 233)",
                      boxShadow: "transaprent",
                    },
                    "&:focus": {
                      border:
                        props.errors.country_id && props.touched.country_id
                          ? "1px solid red"
                          : "2px solid rgb(14 165 233)",
                      boxShadow: "10px 10px 10px #000",
                    },
                  }),
                }}
              />

              {props.errors.country_id && props.touched.country_id ? (
                <p className={FormStyle.error_msg}>{props.errors.country_id}</p>
              ) : null}
            </Stack>
          </Stack>
        </Stack>

        {/* Bio */}
        <Divider sx={{ width: "100%", my: "2.4rem" }} />

        {/* experiences */}
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Total Experience *" />
          <Stack display={"flex"} direction="row" width={"100%"} spacing={2}>
            <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              <Select
                id="experience"
                inputId="experience"
                placeholder="Year"
                value={props.selectedOption.experience}
                name="experience"
                onChange={(selectedOption) => {
                  props.setSelectedOption({ experience: selectedOption });
                  props.setFieldValue("experience", selectedOption.value);
                }}
                onBlur={props.handleBlur}
                isSearchable={true}
                options={JobExperience.map((option) => ({
                  value: option.value,
                  label: option.label.toUpperCase(),
                }))}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    border:
                      props.errors.experience && props.touched.experience
                        ? "2px solid red"
                        : "1px solid rgb(229 231 235)",
                    borderRadius: "8px",
                    padding: "0.1rem",
                    "&:hover": {
                      border:
                        props.errors.experience && props.touched.experience
                          ? "2px solid red"
                          : "1px solid rgb(14 165 233)",
                      boxShadow: "transaprent",
                    },
                    "&:focus": {
                      border:
                        props.errors.experience && props.touched.experience
                          ? "1px solid red"
                          : "2px solid rgb(14 165 233)",
                      boxShadow: "10px 10px 10px #000",
                    },
                  }),
                }}
              />

              {props.errors.experience && props.touched.experience ? (
                <p className={FormStyle.error_msg}>{props.errors.experience}</p>
              ) : null}
            </Stack>
          </Stack>
        </Stack>

        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Applying For *" />

          <Stack display={"flex"} direction="row" width={"100%"} spacing={2}>
            <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              {/* <Select
                id="applying_for"
                inputId="applying_for"
                placeholder="Select your job preference"
                value={props.selectedOption.applying_for}
                name="applying_for"
                onChange={(selectedOption) => {
                  props.setSelectedOption({ applying_for: selectedOption });
                  props.setFieldValue("applying_for", selectedOption.value);
                }}
                onBlur={props.handleBlur}
                isSearchable={true}
                options={props.jobs.map((job) => ({
                  value: job.id,
                  label: job.title,
                }))}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    border:
                      props.errors.applying_for && props.touched.applying_for
                        ? "2px solid red"
                        : "1px solid rgb(229 231 235)",
                    borderRadius: "8px",
                    padding: "0.1rem",
                    "&:hover": {
                      border:
                        props.errors.applying_for && props.touched.applying_for
                          ? "2px solid red"
                          : "1px solid rgb(14 165 233)",
                      boxShadow: "transaprent",
                    },
                    "&:focus": {
                      border:
                        props.errors.applying_for && props.touched.applying_for
                          ? "1px solid red"
                          : "2px solid rgb(14 165 233)",
                      boxShadow: "10px 10px 10px #000",
                    },
                  }),
                }}
              /> */}

              <CustomInputField
                type="text"
                id="applying_for"
                name="applying_for"
                value={props.values.applying_for}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                placeholder="applying_for"
                readOnly={true}
                className={
                  props.errors.applying_for && props.touched.applying_for
                    ? FormStyle.error
                    : ""
                }
              />
              {props.errors.applying_for && props.touched.applying_for ? (
                <p className={FormStyle.error_msg}>
                  {props.errors.applying_for}
                </p>
              ) : null}
            </Stack>
          </Stack>
        </Stack>

        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Job Title *" />

          <Stack display={"flex"} direction="row" width={"100%"} spacing={2}>
            <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              <CustomInputField
                type="text"
                id="jobTitle"
                name="jobTitle"
                value={props.values.job_title}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                placeholder="jobTitle"
                readOnly={true}
                className={
                  props.errors.applying_for && props.touched.applying_for
                    ? FormStyle.error
                    : ""
                }
              />
              {props.errors.applying_for && props.touched.applying_for ? (
                <p className={FormStyle.error_msg}>
                  {props.errors.applying_for}
                </p>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
        {/* qualifiaction */}
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Highest Qualification *" />
          <Stack display={"flex"} direction="row" width={"100%"} spacing={2}>
            <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              <CustomInputField
                maxLength={30}
                type="text"
                id="qualification"
                name="qualification"
                value={props.values.qualification}
                onBlur={props.handleBlur}
                onChange={(e) => {
                  props.setFieldValue(
                    "qualification",
                    e.target.value.replace(/[^a-z.\s]/gi, "")
                  );
                }}
                placeholder="Highest Qualification"
                className={
                  props.errors.qualification && props.touched.qualification
                    ? FormStyle.error
                    : ""
                }
              />
              {props.errors.qualification && props.touched.qualification ? (
                <p className={FormStyle.error_msg}>
                  {props.errors.qualification}
                </p>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
        {/* passingYears */}
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Year of Passing *" />
          <Stack display={"flex"} direction="row" width={"100%"} spacing={2}>
            <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              {/* <SelectYear /> */}
              <CustomInputField
                type="text"
                id="passing-year"
                name="passing_year"
                value={props.values.passing_year}
                onBlur={props.handleBlur}
                onChange={(e) => {
                  props.setFieldValue(
                    "passing_year",
                    e.target.value.replace(/[^\d]/g, "")
                  );
                }}
                maxLength={4}
                placeholder="Passing Year"
                className={
                  props.errors.passing_year && props.touched.passing_year
                    ? FormStyle.error
                    : ""
                }
              />
              {props.errors.passing_year && props.touched.passing_year ? (
                <p className={FormStyle.error_msg}>
                  {props.errors.passing_year}
                </p>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
        {props.values.experience !== "0 to 6 months" &&
          props.values.experience !== "" &&
          props.values.experience !== "Less than 1 year" && (
            <div>
              {/* PreviousOrganization */}
              <Stack
                display={"flex"}
                direction={"column"}
                width={"100%"}
                mb={"1.6rem"}
              >
                <Label name="Current/Previous Organization(s) (if applicable)" />
                <Stack
                  display={"flex"}
                  direction="row"
                  width={"100%"}
                  spacing={2}
                >
                  <Stack
                    item
                    sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}
                  >
                    <CustomInputField
                      maxLength={150}
                      type="text"
                      id="previous_organizations"
                      value={props.values.previous_organizations}
                      name="previous_organizations"
                      placeholder="Current/Previous Organization(s) (if applicable))"
                      onChange={(e) => {
                        props.setFieldValue(
                          "previous_organizations",
                          e.target.value.replace(/[""/'/;/?/<>/\]]/g, "")
                        );
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
              {/* CurrentCtc */}
              <Stack
                display={"flex"}
                direction={"column"}
                width={"100%"}
                mb={"1.6rem"}
              >
                <Label name="Current CTC (in LPA)" />
                <Stack
                  display={"flex"}
                  direction="row"
                  width={"100%"}
                  spacing={2}
                >
                  <Stack
                    item
                    sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}
                  >
                    <CustomInputField
                      maxLength={5}
                      type="text"
                      id="current_ctc"
                      name="current_ctc"
                      value={props.values.current_ctc}
                      onChange={(e) => {
                        props.setFieldValue(
                          "current_ctc",
                          e.target.value
                            .replace(/[^\d.]/g, "")
                            .replace(/(\..*)\./g, "$1")
                        );
                      }}
                      label
                      placeholder="Current CTC (in LPA)"
                    />
                  </Stack>
                </Stack>
              </Stack>
              {/* Expected CTC */}
              <Stack
                display={"flex"}
                direction={"column"}
                width={"100%"}
                mb={"1.6rem"}
              >
                <Label name="Expected CTC (in LPA)" />
                <Stack
                  display={"flex"}
                  direction="row"
                  width={"100%"}
                  spacing={2}
                >
                  <Stack
                    item
                    sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}
                  >
                    <CustomInputField
                      maxLength={5}
                      type="text"
                      id="expected_ctc"
                      name="expected_ctc"
                      value={props.values.expected_ctc}
                      onChange={(e) => {
                        props.setFieldValue(
                          "expected_ctc",
                          e.target.value
                            .replace(/[^\d.]/g, "")
                            .replace(/(\..*)\./g, "$1")
                        );
                      }}
                      placeholder="Expected CTC"
                      className={
                        props.errors.expected_ctc && props.touched.expected_ctc
                          ? FormStyle.error
                          : ""
                      }
                    />
                    {props.errors.expected_ctc && props.touched.expected_ctc ? (
                      <p className={FormStyle.error_msg}>
                        {props.errors.expected_ctc}
                      </p>
                    ) : null}
                  </Stack>
                </Stack>
              </Stack>
              {/* Reason for Change */}
              <Stack
                display={"flex"}
                direction={"column"}
                width={"100%"}
                mb={"1.6rem"}
              >
                <Label name="Reason for Change" />
                <Stack
                  display={"flex"}
                  direction="row"
                  width={"100%"}
                  spacing={2}
                >
                  <Stack
                    item
                    sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}
                  >
                    <TextareaAutosize
                      maxLength={500}
                      id="reason"
                      name="reason"
                      className={`${FormStyle.textArea} ${FormStyle.field_focus}`}
                      sx={{ text: "black" }}
                      placeholder="Reason for Change"
                      minRows={10}
                      maxRows={5}
                      value={props.values.reason}
                      onChange={(e) => {
                        props.setFieldValue(
                          "reason",
                          e.target.value.replace(/[<>/\]]/g, "")
                        );
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
              {/* Notice Period */}
              <Stack
                display={"flex"}
                direction={"column"}
                width={"100%"}
                mb={"1.6rem"}
              >
                <Label name="Notice Period (months) " />
                <Stack
                  display={"flex"}
                  direction="row"
                  width={"100%"}
                  spacing={2}
                >
                  <Stack
                    item
                    sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}
                  >
                    <CustomInputField
                      maxLength={2}
                      type="text"
                      id="notice_period"
                      name="notice_period"
                      value={props.values.notice_period}
                      onBlur={props.handleBlur}
                      onChange={(e) => {
                        props.setFieldValue(
                          "notice_period",
                          e.target.value.replace(/[^\d]/g, "")
                        );
                      }}
                      placeholder="Notice Period"
                      className={
                        props.errors.notice_period &&
                        props.touched.notice_period
                          ? FormStyle.error
                          : ""
                      }
                    />
                    {props.errors.notice_period &&
                    props.touched.notice_period ? (
                      <p className={FormStyle.error_msg}>
                        {props.errors.notice_period}
                      </p>
                    ) : null}
                  </Stack>
                </Stack>
              </Stack>
            </div>
          )}
        {/* Current Location */}
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Current Location *" />
          <Stack display={"flex"} direction="row" width={"100%"} spacing={2}>
            <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              <CustomInputField
                maxLength={150}
                type="text"
                id="current_location"
                name="current_location"
                value={props.values.current_location}
                onBlur={props.handleBlur}
                onChange={(e) => {
                  props.setFieldValue(
                    "current_location",
                    e.target.value.replace(/[<>/\]]/g, "")
                  );
                }}
                placeholder="Current Location"
                className={
                  props.errors.current_location &&
                  props.touched.current_location
                    ? FormStyle.error
                    : ""
                }
              />
              {props.errors.current_location &&
              props.touched.current_location ? (
                <p className={FormStyle.error_msg}>
                  {props.errors.current_location}
                </p>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
        {/* Prefferd Location */}
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Preferred Location (if applicable)" />
          <Stack display={"flex"} direction="row" width={"100%"} spacing={2}>
            <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              <Select
                id="preferred_location"
                inputId="preferred_location"
                placeholder="Select your preferred location"
                name="preferred_location"
                value={props.selectedOption.preferred_location}
                onChange={(selectedOption) => {
                  props.setSelectedOption({
                    preferred_location: selectedOption,
                  });
                  props.setFieldValue(
                    "preferred_location",
                    selectedOption.value
                  );
                }}
                onBlur={props.handleBlur}
                isSearchable={true}
                options={preferredLocations.map((preferredLocation) => ({
                  value: preferredLocation.value,
                  label: preferredLocation.label,
                }))}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    border:
                      props.errors.preferred_location &&
                      props.touched.preferred_location
                        ? "2px solid red"
                        : "1px solid rgb(229 231 235)",
                    borderRadius: "8px",
                    padding: "0.1rem",
                    "&:hover": {
                      border:
                        props.errors.preferred_location &&
                        props.touched.preferred_location
                          ? "2px solid red"
                          : "1px solid rgb(14 165 233)",
                      boxShadow: "transaprent",
                    },
                    "&:focus": {
                      border:
                        props.errors.preferred_location &&
                        props.touched.preferred_location
                          ? "1px solid red"
                          : "2px solid rgb(14 165 233)",
                      boxShadow: "10px 10px 10px #000",
                    },
                  }),
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={{ width: "100%", my: "2.4rem" }} />
        {/* IDS Interview */}

        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Taken IDS interviews recently? *" />
          <RadioGroup
            type="text"
            id="is_recent_interview"
            name="is_recent_interview"
            value={props.values.is_recent_interview}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
          >
            <FormControlLabel
              value="yes"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "primary.primary600",
                    },
                    "&:hover": {
                      boxShadow: "0 0 1px rgba(0, 0, 11, 0.3)",
                    },
                  }}
                />
              }
              label="Yes"
            />
            <FormControlLabel
              value="no"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "primary.primary600",
                    },
                    "&:hover": {
                      boxShadow: "0 0 1px rgba(0, 0, 11, 0.3)",
                    },
                  }}
                />
              }
              label="No"
            />
          </RadioGroup>
          {props.errors.is_recent_interview &&
          props.touched.is_recent_interview ? (
            <p className={FormStyle.error_msg}>
              {props.errors.is_recent_interview}
            </p>
          ) : null}
        </Stack>
        {/* Joint Ventures */}
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Any employment history with IDS or Joint Ventures (IDEAS or IDS-Argus)? *" />
          <Stack item sx={{ width: { md: "100%" } }}>
            <RadioGroup
              type="text"
              id="has_history"
              name="has_history"
              value={props.values.has_history}
              onBlur={props.handleBlur}
              onChange={props.handleChange}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
            >
              <FormControlLabel
                value="yes"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "primary.primary600",
                      },
                      "&:hover": {
                        boxShadow: "0 0 1px rgba(0, 0, 11, 0.3)",
                      },
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "primary.primary600",
                      },
                      "&:hover": {
                        boxShadow: "0 0 1px rgba(0, 0, 11, 0.3)",
                      },
                    }}
                  />
                }
                label="No"
              />
            </RadioGroup>
            {props.errors.has_history && props.touched.has_history ? (
              <p className={FormStyle.error_msg}>{props.errors.has_history}</p>
            ) : null}
            {props.values.has_history === "yes" && (
              <Stack
                display="flex"
                direction={"column"}
                sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}
                spacing={2}
                my={4}
              >
                <Label name="Department Name *" />
                <CustomInputField
                  maxLength={50}
                  type="text"
                  id="department"
                  name="department"
                  values={props.values.department}
                  onBlur={props.handleBlur}
                  onChange={(e) => {
                    props.setFieldValue(
                      "department",
                      e.target.value.replace(/[^a-z.\s]/gi, "")
                    );
                  }}
                  placeholder="Department Name"
                  className={
                    props.errors.department && props.touched.department
                      ? FormStyle.error
                      : ""
                  }
                />
                {props.errors.department && props.touched.department ? (
                  <p className={FormStyle.error_msg} style={{ margin: "0px" }}>
                    {props.errors.department}
                  </p>
                ) : null}
                <Label name="Employee Code *" />
                <CustomInputField
                  maxLength={10}
                  type="text"
                  id="emp_code"
                  name="emp_code"
                  value={props.values.emp_code}
                  onBlur={props.handleBlur}
                  onChange={(e) => {
                    props.setFieldValue(
                      "emp_code",
                      e.target.value.replace(/[^a-zA-Z0-9]/g, "")
                    );
                  }}
                  placeholder="Employee Code"
                  className={
                    props.errors.emp_code && props.touched.emp_code
                      ? FormStyle.error
                      : ""
                  }
                />
                {props.errors.emp_code && props.touched.emp_code ? (
                  <p className={FormStyle.error_msg} style={{ margin: "0px" }}>
                    {props.errors.emp_code}
                  </p>
                ) : null}
              </Stack>
            )}
          </Stack>
        </Stack>
        {/* Certification */}
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Documents *" />
          <Stack
            display={"flex"}
            direction={{ md: "row" }}
            width={"100%"}
            spacing={2}
          >
            <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              <UploadFile
                title={"Resume"}
                name={"documents"}
                folderPath="applications/documents"
                setFieldValue={props.setFieldValue}
                handleBlur={props.handleBlur}
                setFieldTouched={props.setFieldTouched}
                values={{ documents: props.values.documents }}
                accept=".pdf, .docx, .doc"
                numberOfFiles={3}
                isMultiple={true}
                errorMessage={props.errors?.documents}
                errorStatus={
                  props.errors?.documents !== undefined &&
                  props.errors?.documents !== undefined
                    ? true
                    : false
                }
              />
              {/* {props.files.documents.data.length <= 1 && (
                <FileUploadComponent
                  className={`${FormStyle.FileUpload} ${
                    props.errors.documents && props.touched.documents
                      ? FormStyle.FileUpload_error
                      : ""
                  }`}
                  name="documents"
                  id="documents"
                  uploadFilename="documents"
                  onBlur={props.handleBlur}
                  onHandleDrag={props.onHandleDrag}
                  onHandleDrop={props.onHandleDrop}
                  setIsDrag={props.setIsDrag}
                  isDrag={props.isDrag}
                  onChange={props.uploadAllFiles}
                  uploadRef={props.uploadRef}
                />
              )}
              {props.errors.documents && props.touched.documents && (
                <p className={FormStyle.error_msg}>{props.errors.documents}</p>
              )}
              {props.files.documents.data.length > 0 &&
                props.files.documents.isUploaded &&
                [...props.files.documents.data]
                  .reverse()
                  .map((file, index) => (
                    <FileUploaded
                      key={index}
                      file={file}
                      deleteFileName="documents"
                      percentage={props.percentage}
                      deleteUploadFile={props.deleteUploadFile}
                      isDeleteUpload={props.isDeleteUpload}
                    />
                  ))}
              {props.files.documents.isUploading && (
                <FileUploading progress={props.percentage} />
              )} */}
              {/* {props.files.documents.isError && (
                <ErrorFileUploadComponent file={props.files.documents} />
              )} */}
            </Stack>
          </Stack>
        </Stack>
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Certificate" />
          <Stack display={"flex"} direction="row" width={"100%"} spacing={2}>
            <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              <UploadFile
                title={"Certificate"}
                name={"certificates"}
                folderPath="applications/certificates"
                setFieldValue={props.setFieldValue}
                handleBlur={props.handleBlur}
                setFieldTouched={props.setFieldTouched}
                values={{ certificate: props.values.certificate }}
                accept=".pdf, .docx, .doc"
                errorMessage={props.errors?.certificate}
                errorStatus={
                  props.errors?.certificate !== undefined &&
                  props.errors?.certificate !== undefined
                    ? true
                    : false
                }
              />
              {/* {props.files.certificates.data.length === 0 && (
                <FileUploadComponent
                  className={`${FormStyle.FileUpload}`}
                  name="certificates"
                  id="certificates"
                  uploadFilename="certificates"
                  onBlur={props.handleBlur}
                  onHandleDrag={props.onHandleDrag}
                  onHandleDrop={props.onHandleDrop}
                  setIsDrag={props.setIsDrag}
                  isDrag={props.isDrag}
                  onChange={props.uploadAllFiles}
                  uploadRef={props.uploadRef}
                />
              )}
              {props.files.certificates.data.length > 0 &&
                props.files.certificates.isUploaded &&
                [...props.files.certificates.data]
                  .reverse()
                  .map((file, index) => (
                    <FileUploaded
                      key={index}
                      file={file}
                      deleteFileName="certificates"
                      percentage={props.percentage}
                      deleteUploadFile={props.deleteUploadFile}
                    />
                  ))}
              {props.files.certificates.isUploading && (
                <FileUploading progress={props.percentage} />
              )} */}
            </Stack>
          </Stack>
        </Stack>
        {/* Awards */}
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Awards" />
          <Stack display={"flex"} direction="row" width={"100%"} spacing={2}>
            <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              <UploadFile
                title={"Certificate"}
                name={"certificates"}
                folderPath="applications/certificates"
                setFieldValue={props.setFieldValue}
                handleBlur={props.handleBlur}
                setFieldTouched={props.setFieldTouched}
                values={{ certificate: props.values.certificate }}
                accept=".pdf, .docx, .doc"
                errorMessage={props.errors?.certificate}
                errorStatus={
                  props.errors?.certificate !== undefined &&
                  props.errors?.certificate !== undefined
                    ? true
                    : false
                }
              />
              {/* {props.files.awards.data.length === 0 && (
                <FileUploadComponent
                  className={`${FormStyle.FileUpload}`}
                  name="awards"
                  id="awards"
                  uploadFilename="awards"
                  values={props.values.files}
                  onBlur={props.handleBlur}
                  onHandleDrag={props.onHandleDrag}
                  onHandleDrop={props.onHandleDrop}
                  setIsDrag={props.setIsDrag}
                  isDrag={props.isDrag}
                  onChange={props.uploadAllFiles}
                  uploadRef={props.uploadRef}
                />
              )}
              {props.files.awards.data.length > 0 &&
                props.files.awards.isUploaded &&
                [...props.files.awards.data]
                  .reverse()
                  .map((file, index) => (
                    <FileUploaded
                      key={index}
                      file={file}
                      deleteFileName="awards"
                      percentage={props.percentage}
                      deleteUploadFile={props.deleteUploadFile}
                    />
                  ))}
              {props.files.awards.isUploading && (
                <FileUploading progress={props.percentage} />
              )} */}
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={{ width: "100%", my: "2.4rem" }} />
        {/* linkdin */}
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Linkedin" />
          <Stack display={"flex"} direction="row" width={"100%"} spacing={2}>
            <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              <Stack display={"flex"} direction="row" width={"100%"}>
                <Stack item width={"100%"}>
                  <InputLink
                    type="text"
                    name="linkedin_url"
                    value={props.values.linkedin_url}
                    onBlur={props.handleBlur}
                    setFieldValue={props.setFieldValue}
                    placeholder="linkedin.com/"
                    className={`${FormStyling.input_links} ${
                      props.errors.linkedin_url && props.touched.linkedin_url
                        ? FormStyling.error_input_links
                        : ""
                    }`}
                  />
                </Stack>
              </Stack>

              {/* {props.errors.linkedin_url && props.touched.linkedin_url ? (
                <p className={FormStyle.error_msg}>
                  {props.errors.linkedin_url}
                </p>
              ) : null} */}
            </Stack>
          </Stack>
        </Stack>
        {/* drive link */}
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Drive Link or Portfolio URL" />
          <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
            <Stack display={"flex"} direction="row" width={"100%"}>
              {/* <Stack item width={"20%"}>
                    <InputLink
                      type="text"
                      disabled
                      value="Drive Link"
                      className={FormStyling.disabled_link}
                    />
                  </Stack> */}
              <Stack item width={"100%"}>
                <InputLink
                  type="text"
                  name="drive_link"
                  value={props.values.drive_link}
                  onBlur={props.handleBlur}
                  setFieldValue={props.setFieldValue}
                  placeholder="Link"
                  className={`${FormStyling.input_links}`}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {/* Blogs_link */}
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Link to Online/Published Project" />
          <Stack
            display={"flex"}
            direction={{ md: "row" }}
            width={"100%"}
            spacing={2}
          >
            <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              <Stack display={"flex"} direction="row" width={"100%"}>
                {/* <Stack item width={"20%"}>
                    <InputLink
                      type="text"
                      disabled
                      value="linkedin.com/"
                      className={FormStyling.disabled_link}
                    />
                  </Stack> */}
                <Stack item width={"100%"}>
                  <InputLink
                    type="text"
                    name="blogs"
                    value={props.values.blogs}
                    onBlur={props.handleBlur}
                    setFieldValue={props.setFieldValue}
                    placeholder="Link to Online/Published Projects"
                    className={`${FormStyling.input_links}`}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          display={"flex"}
          direction={"column"}
          width={"100%"}
          mb={"1.6rem"}
        >
          <Label name="Leave a Message" />
          <Stack display={"flex"} direction="row" width={"100%"} spacing={2}>
            <Stack item sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
              <TextareaAutosize
                maxLength={500}
                id="message"
                name="message"
                className={`${FormStyle.textArea} ${FormStyle.field_focus}`}
                sx={{ text: "black" }}
                placeholder="Write your Message"
                minRows={10}
                maxRows={5}
                value={props.values.message}
                onChange={(e) => {
                  props.setFieldValue(
                    "message",
                    e.target.value.replace(/[<>/\]]/g, "")
                  );
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ width: { md: "100%" }, mx: { md: "auto" }, mb: "2rem" }}>
          <Stack
            display={"flex"}
            direction={{ md: "row" }}
            spacing={0}
            width={"100%"}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="checked"
                  value={props.values.checked}
                  checked={props.values.checked === true ? true : false}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  sx={{
                    "&.Mui-checked": {
                      color: "primary.primary600",
                    },
                  }}
                />
              }
            />
            <Typography sx={{ width: { md: "100%" } }}>
              I confirm my consent for IDS to process my personal information to
              fulfill this request in compliance with IDS Infotech Limited's
              <span>
                <a
                  style={{ paddingLeft: "0.2rem" }}
                  rel="noreferrer"
                  target="_blank"
                  href="https://idsil.com/policies/privacy"
                >
                  Privacy Statement
                </a>
                .
              </span>
            </Typography>
          </Stack>
          {props.errors.checked && props.touched.checked ? (
            <p className={FormStyle.error_msg}>{props.errors.checked}</p>
          ) : null}
        </Stack>
        {/* // ? ******************** Recaptcha ****************************** */}
        <Stack sx={{ width: { md: "100%" }, mb: "2rem" }}>
          <Stack
            display={"flex"}
            direction={{ md: "column" }}
            spacing={0}
            width={"100%"}
          >
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              ref={props.captchaRef}
              name="token"
              onBlur={props.handleBlur}
              onChange={(value) => {
                props.setFieldValue("token", value);
              }}
            />
            {props.errors.token && props.touched.token ? (
              <p className={FormStyle.error_msg}>{props.errors.token}</p>
            ) : null}
          </Stack>
        </Stack>

        <Stack width={"100%"} mx={"auto"}>
          <Button
            type="submit"
            variant="contained"
            py={2}
            sx={[
              {
                // typography: "textXlReg",
                color: "white",
                bgcolor: "primary.blue600",
                fontSize: "16px",
                fontWeight: "600",
                padding: "12px 20px",
                lineHeight: "24px",
              },
              {
                "&:hover": {
                  bgcolor: "primary.blue700",
                },
              },
            ]}
            disableElevation
            disabled={props.isSubmitting}
            onClick={() => {
              const err = Object.keys(props.errors);
              console.log(err);
              if (err.length) {
                const input = document.querySelector(`input[name=${err[0]}]`);

                return input.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                  inline: "start",
                });
              }
            }}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default Form;
