import { AppBar } from "@mui/material";
import React, { useState } from "react";
import NavToolBar from "./Navbar/NavToolBar";
import NavSwipeDrawer from "./Navbar/NavSwipeDrawer";

const buttonStyle = [
  {
    color: "primary.gray600",
    fontSize: "16px",
    fontWeight: "600",
    padding: "12px 20px",
    lineHeight: "24px",
  },
  {
    "&:hover": {
      bgcolor: "primary.gray100",
      color: "primary.blue700",
      cursor: "pointer",
    },
  },
];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <AppBar
      sx={{
        position: "fixed",
        boxShadow: "none",
        width: "100%",
        backgroundColor: "grey.main",
      }}
    >
      <NavToolBar
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonStyle={buttonStyle}
      />
      <NavSwipeDrawer
        buttonStyle={buttonStyle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </AppBar>
  );
};

export default Navbar;
