const clients = [
  {
    heading: "34+",
    mainHeading: "Years Industry Experience",
    subHeading: `Transforming digital space with in-depth knowledge & expertise`,
  },
  {
    heading: "1300+",
    mainHeading: "Talented Professionals",
    subHeading: `Accelerating customer success with domain-specific expertise`,
  },
  {
    heading: "4",
    mainHeading: "Global Presence",
    subHeading: "Creating impact worldwide, with global footprints",
  },
  {
    heading: "2",
    mainHeading: "Joint Ventures",
    subHeading: "Providing cutting-edge solutions with industry-experts",
  },
];
export default clients;
