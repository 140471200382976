import React from "react";
import classes from "./DragAndDrop.module.css";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const DragAndDrop = (props) => {
  return (
    <div
      className={classes.form_file_upload}
      onDragEnter={props.onDragFileHandler}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <input
        onBlur={(e) => {
          props.handleBlur(e);
          // console.log("props.name: ", props.name);
          // props.setFieldTouched(props.name, true);
          // props.setFieldValue(props.name, null);
        }}
        ref={props.inputRef}
        type="file"
        name={props.name}
        className={classes.input_file_upload}
        multiple={true}
        onChange={(e) => {
          e.preventDefault();
          props.onFileChangeHandler(e.target.files);
        }}
        accept={props.accept}
      />
      <label
        className={`"${props.isActiveDrag && "drag-active"} ${
          classes.label_file_upload
        }`}
        htmlFor="input_file_upload"
      >
        <div>
          <p>Drag and drop your {props.title}</p>
          <div
            className={classes.upload_button}
            onClick={() => {
              props.inputRef.current.click();
              props.setFieldTouched(props.name, true);
            }}
          >
            <UploadFileIcon /> Upload Picture
          </div>
        </div>
      </label>
      {props.isActiveDrag && (
        <div
          className={classes.drag_file_element}
          onDragEnter={props.onDragFileHandler}
          onDragLeave={props.onDragFileHandler}
          onDragOver={props.onDragFileHandler}
          onDrop={props.onDropFileHandler}
        ></div>
      )}
    </div>
  );
};

export default DragAndDrop;
