import { Stack, Typography } from "@mui/material";
import React from "react";

const TestimonialCard = (props) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      height={350}
      color="primary.gray50"
      py={{ xs: "1.2rem", md: "3rem" }}
      px={{ xs: "1.2rem", md: "2rem" }}
    >
      <Typography sx={{ typography: { md: "textSmSemi" } }}>
        {props.subHeading}
      </Typography>
      <Typography
        sx={{
          typography: { md: "displayMdMed" },
          width: { sm: "100%", md: "80%" },
        }}
      >
        {props.heading}
      </Typography>
      {/* <Stack my="1.3rem">
        <Avatar alt="Remy Sharp" src={props.avatar}>
          <Box
            component={"img"}
            src={props.avatar}
            layout="fill"
            alt="avatar"
          />
        </Avatar>
      </Stack> */}
      <Typography my={1} sx={{ typography: { md: "textLgSemi" } }}>
        {props.name}
      </Typography>
      <Typography sx={{ typography: { md: "textSmReg" } }}>
        {props.designation}
      </Typography>
    </Stack>
  );
};

export default TestimonialCard;
