import { Grid, Stack } from "@mui/material";
import React from "react";
import PaperContainer from "../layouts/PaperContainer";
import clients from "../../store/ClientData";
import ClientCard from "../cards/ClientCard";

const Journey = () => {
  return (
    <PaperContainer bgColor="#f6f6f6">
      <Stack
        className="zoom-on-scroll"
        py={2}
        sx={{ my: "6rem" }}
        mx={"auto"}
        maxWidth={"xl"}
      >
        <Grid container spacing={{ md: "2rem", xl: "4rem" }}>
          {clients.map((client, index) => (
            <Grid item lg={3} key={index}>
              <ClientCard
                heading={client.heading}
                mainHeading={client.mainHeading}
                subHeading={client.subHeading}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </PaperContainer>
  );
};

export default Journey;
