import * as Yup from "yup";

// ? ************************************************************** Referral Schema ************************************************************** */
export const referSchema = Yup.object().shape({
  first_name: Yup.string().min(3).required("Please enter candidate’s name"),
  email: Yup.string().email().required("Please enter candidate’s valid email"),
  experience: Yup.mixed().required("Please select candidate’s experience"),
  resume: Yup.mixed().required("Please upload candidate’s resume "),
});

// ? ************************************************************** Referred By Schema ************************************************************** */
export const referredBYSchema = Yup.object().shape({
  first_name: Yup.string().min(3).required("Please enter your name "),
  email: Yup.string()
    .email()
    .matches(/@idsil.com$/, "Enter your official email")
    .required("Enter your official email"),
  employee_code: Yup.string()
    .max(8)
    .required("Please enter your employee code"),
  is_worked: Yup.mixed().required(
    "Please choose your work experience with the candidate "
  ),
  department_id: Yup.string().required("Please select your department"),
  is_actively_looking: Yup.mixed().required("Please select an option "),
  disclaimer: Yup.bool().oneOf([true], "Checkbox selection is required"),
  token: Yup.string().required("Recaptcha validation is required"),
});

// ? ************************************************************** Application Schema ************************************************************** */
export const FormSchema = Yup.object({
  first_name: Yup.string()
    .required("Please enter your first name")
    .matches(/^[A-Za-z]+$/, "First name must contain only letters")
    .max(30, "You have reached your maximum limit of characters allowed")
    .min(3, "First name must be 3 character"),
  last_name: Yup.string()
    .matches(/^[A-Za-z]+$/, "Last name must contain only letter")
    .max(30, "You have reached your maximum limit of characters allowed")
    .min(3, "Last name must be 3 character"),

  phone: Yup.string()
    .required("Please enter your contact number")
    .matches(/^(?:[0-9] ?){9,14}[0-9]$/, "Invalid phone number"),

  email: Yup.string()
    .required("Please enter your email")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/g,
      "Invalid email format"
    ),
  country_id: Yup.string().required("Please select your country"),
  experience: Yup.string().required("Please select your experience"),
  applying_for: Yup.string().required("Please select your job preference"),
  qualification: Yup.string()
    .max(40)
    .required("Please enter your highest qualification"),
  passing_year: Yup.number().required("Please enter year of passing"),
  notice_period: Yup.string().max(
    10,
    "Notice period must be 10 character only"
  ),
  current_location: Yup.string()
    .max(15, "Current location must be 15 character only")
    .required("Please enter your current location"),
  has_history: Yup.string().required("Please select an option"),
  department: Yup.string().when("has_history", {
    is: (value) => value === "yes",
    then: () => Yup.string().required("Please enter your department name"),
    otherwise: () => Yup.string().notRequired(),
  }),
  emp_code: Yup.string().when("has_history", {
    is: (value) => value === "yes",
    then: () => Yup.string().required("Please enter your employee code"),
    otherwise: () => Yup.string().notRequired(),
  }),
  is_recent_interview: Yup.string().required("Please select an option"),
  checked: Yup.bool().oneOf([true], "Checkbox selection is required"),
  documents: Yup.mixed().required("Please upload a file"),
  token: Yup.string().required("Recaptcha validation is required"),
});
