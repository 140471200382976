import { Skeleton, Stack } from "@mui/material";
import React from "react";

function ListSkeleton() {
  return (
    <Stack display={"flex"} justifyContent="center" mx={"auto"} maxWidth={"md"}>
      <Skeleton sx={{ my: 2, mx: "auto" }} variant="text" width={"30%"} />
      <Skeleton variant="text" width={"100%"} />
      <Skeleton variant="text" width={"100%"} />

      <Skeleton sx={{ mb: 4 }} variant="text" width={"100%"} />
      <Stack
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        gap={4}
        flexDirection={"row"}
      >
        <Skeleton variant="rounded" width={200} height={50} />
        <Skeleton variant="rounded" width={200} height={50} />
        <Skeleton variant="rounded" width={"100%"} height={50} />
      </Stack>

      <Skeleton sx={{ mt: 6 }} variant="rounded" width={"100%"} height={200} />
      <Skeleton sx={{ mt: 6 }} variant="rounded" width={"100%"} height={200} />
      <Skeleton sx={{ mt: 6 }} variant="rounded" width={"100%"} height={200} />
    </Stack>
  );
}

export default ListSkeleton;
