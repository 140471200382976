import { Button, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import Lottie from "lottie-react";
import SuccessAnimation from "../assets/json/success.json";
import FailedAnimation from "../assets/json/failed.json";
import { forwardRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Response = forwardRef((props, ref) => {
  const params = useParams();
  const navigate = useNavigate();

  const handleBack = () => {
    props.setIsResponse({
      status: false,
      type: "",
      message: "",
      subText: "",
      buttonText: "",
    });
    return navigate(`/applications/${params.id}/referral`);
  };
  return (
    <div ref={ref}>
      <Stack
        display={"flex"}
        my={10}
        justifyContent={"center"}
        alignItems="center"
      >
        <Box maxWidth={{ xs: "15%", md: "5%" }}>
          <Lottie
            animationData={
              props.response.status ? SuccessAnimation : FailedAnimation
            }
            loop={true}
          />
        </Box>
        <Typography
          my={2}
          mt={0}
          mx={3}
          textAlign={"center"}
          sx={{ typography: "textXlSemi", color: "primary.gray800" }}
        >
          {props.response.message}
        </Typography>
        <Typography
          my={2}
          mt={0}
          mx={3}
          maxWidth={{ xs: "75%", md: "20%" }}
          textAlign={"center"}
          sx={{ typography: "textLgReg", color: "primary.gray600" }}
        >
          {/* {props.response.subText} */}
        </Typography>
        <Button
          type="submit"
          variant="contained"
          py={1}
          sx={[
            {
              color: "white",
              bgcolor: "primary.primary600",
              fontSize: "16px",
              fontWeight: "600",
              padding: "12px 20px",
              lineHeight: "24px",
            },
            {
              "&:hover": {
                bgcolor: "primary.primary700",
              },
            },
          ]}
          onClick={handleBack}
        >
          Back
        </Button>
      </Stack>
    </div>
  );
});

export default Response;
