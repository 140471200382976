import React from "react";
export default class useApi extends React.Component {
  static baseUrl = process.env.REACT_APP_API_URL;
  static defaultHeaders = {
    "content-type": "application/json",
    "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
    "Content-Security-Policy": "directive 'source';",
    "X-Frame-Options": "SAMEORIGIN",
  };

  // ? ********************************** Post Request ******************************** */
  static async postRequest(
    url,
    isToken = true,
    formData = null,
    headers = null,
    params = null
  ) {
    try {
      const response = await fetch(`${this.baseUrl}${url}`, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: this.config(isToken, url, headers, params),
      });
      if (!response.ok) {
        throw new Error("Something Went wrong! Try again!");
      }
      return await response.json();
    } catch (error) {
      return error;
    }
  }

  // ? ******************************** Get Request ******************************** */
  static async getRequest(url, isToken = true, headers = null, params = null) {
    try {
      const response = await fetch(`${this.baseUrl}${url}`, {
        method: "GET",
        headers: this.config(isToken, url, headers, params),
      });
      if (!response.ok) {
        throw new Error("Something Went wrong! Try again!");
      }
      return await response.json();
    } catch (error) {
      return error;
    }
  }

  // ? ******************************** Delete Request ******************************** */
  static async deleteRequest(
    url,
    isToken = true,
    headers = null,
    params = null
  ) {
    try {
      const response = await fetch(`${this.baseUrl}${url}`, {
        method: "DELETE",
        headers: this.config(isToken, url, headers, params),
      });
      if (!response.ok) {
        throw new Error("Something Went wrong! Try again!");
      }
      return await response.json();
    } catch (error) {
      return error;
    }
  }

  // ? *********************** Update request with patch method *************************** */
  static async updateRequest(
    url,
    isToken = true,
    formData = null,
    headers = null,
    params = null
  ) {
    try {
      const response = await fetch(`${this.baseUrl}${url}`, {
        method: "PATCH",
        body: JSON.stringify(formData),
        headers: this.config(isToken, url, headers, params),
      });
      if (!response.ok) {
        throw new Error("Something went wrong. Try again!");
      }
      return await response.json();
    } catch (error) {
      return error;
    }
  }

  // ? ***************** Configurations of header and parameters ************************ */
  static config(isToken = true, url, headers = null, parameters = null) {
    const apiKeyName =
      process.env[
        `REACT_APP_${url
          .split("/")[0]
          .toUpperCase()
          .replace("-", "_")
          .trim()}_API_KEY`
      ];

    this.defaultHeaders = { ...this.defaultHeaders, "x-api-key": apiKeyName };

    if (isToken) {
      const token = localStorage.getItem("token");
      if (token === null) {
        throw new Error("Access Denied. Try again!");
      }
      this.defaultHeaders = {
        ...this.defaultHeaders,
        "is-authentication": true,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
    }

    if (headers !== null) {
      this.defaultHeaders = { ...this.defaultHeaders, ...headers };
    }

    return this.defaultHeaders;
  }

  // ? ***************** Throw the new errors According to status codes ************************ */
  static throwNewErrors(message, statusCode) {
    // throw Error(JSON.stringify({ code: statusCode, message: message }));
    throw Error(message);
  }
}
