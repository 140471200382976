import React, { useEffect, useRef, useState } from "react";
import ReferredByForm from "../components/form/ReferredByForm";
import Toast from "../utils/Toast";
import { referredBYSchema } from "../schema";
import { useFormik } from "formik";
import {
  useActionData,
  useLocation,
  useNavigate,
  useNavigation,
  useOutletContext,
  useParams,
  useRouteLoaderData,
  useSubmit,
} from "react-router-dom";
import useApi from "../hooks/use-api";
import Response from "../utils/Response";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  employee_code: "",
  is_worked: "",
  is_actively_looking: "",
  department_id: "",
  referral_id: "",
  message: "",
  disclaimer: false,
  token: "",
};

const ReferredBy = () => {
  const { departments } = useRouteLoaderData("opening");
  const { opening } = useOutletContext();
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const submit = useSubmit();
  const responseRef = useRef(null);
  const [isResponse, setIsResponse] = useState({
    status: false,
    type: "",
    message: "",
    subText: "",
    buttonText: "",
  });

  const actionData = useActionData();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  // ? ****************************** Submit the form **************************** */
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: referredBYSchema,
    onSubmit: async (values) => {
      return submit(values, { method: "POST" });
    },
  });

  useEffect(() => {
    if (actionData?.status) {
      resetForm();
      setIsResponse({
        status: true,
        type: "success",
        message:
          "Submitted Successfully. Please check your inbox for further information.",
        subText:
          "Thank you for showing interest in our products/services. We will be in touch soon.",
        buttonText: "Back",
      });
      setTimeout(() => {
        responseRef.current.scrollIntoView({ behavior: "smooth" });
      }, 1000);
      return;
    }

    if (actionData?.status === false) {
      Toast.fire({ icon: "error", title: actionData.message });
      return;
    }

    if (state === null || state === undefined || state.referral === undefined) {
      return navigate(`/applications/${params.id}/referral`);
    }
    setFieldValue("referral", JSON.stringify(state.referral));
    // return navigate("/");
  }, [actionData, resetForm, setFieldValue, state, navigate, params.id]);

  return (
    <>
      {!isResponse.status && (
        <ReferredByForm
          opening={opening}
          departments={departments}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          isResponse={isResponse}
          isSubmitting={isSubmitting}
          handleBlur={handleBlur}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      )}
      {isResponse.status && (
        <Response
          response={isResponse}
          setIsResponse={setIsResponse}
          ref={responseRef}
        />
      )}
    </>
  );
};

export default ReferredBy;

export const action = async ({ request }) => {
  try {
    const data = await request.formData();
    const formData = {
      referredBy: {
        first_name: data.get("first_name"),
        last_name: data.get("last_name"),
        email: data.get("email"),
        department_id: data.get("department_id"),
        employee_code: data.get("employee_code"),
        is_worked: data.get("is_worked"),
        is_actively_looking: data.get("is_actively_looking"),
        checkField: data.get("check_field"),
      },
      referral: JSON.parse(data.get("referral")),
    };
    const result = await useApi.postRequest(
      "referred-by",
      false,
      formData,
      "REACT_APP_API_KEY_REFERRAL"
    );

    return result;
  } catch (error) {
    return Toast.fire({ icon: "error", title: error.message });
  }
};
