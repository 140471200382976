import { Stack, Typography } from "@mui/material";
import React from "react";

const FormHeading = (props) => {
  return (
    <>
      <Stack>
        <Stack sx={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            sx={{
              typography: { xs: "textSmSemi", md: "textMdSemi" },
              color: "primary.blue700",
            }}
          >
            {props.isShowReferre ? "Candidate Info" : " Personal Info"}
          </Typography>
          <Typography
            color={"primary.blue800"}
            sx={{
              typography: { xs: "displayMdSemi", md: "displayMdSemi" },
            }}
            my={2}
          >
            Join Our Dynamic Team
          </Typography>
          <Stack sx={{ width: "100%" }} display={"flex"} alignItems={"center"}>
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: "center",
                typography: { xs: "textMdReg", md: "textMdReg" },
                // mx: { xs: "0rem", md: "10rem" },
                color: "grey.grey600",
                width: { xs: "100%", md: "50%" },
              }}
            >
              {/* 
                            {props.isShowReferre ? "Help us get to know you better by filling out this form. Your details are essential for aligning you with suitable opportunities." : "Complete this form and help us understand your potential. We're eager to learn about you. "} */}

              {props.isShowReferre
                ? "Complete this form and help us understand your potential. We're eager to learn about you."
                : "Help us get to know you better by filling out this form. Your details are essential for aligning you with suitable opportunities."}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default FormHeading;
