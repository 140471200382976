import React from "react";
import { Stack } from "@mui/system";
import Carousel from "react-material-ui-carousel";
import PaperContainer from "../layouts/PaperContainer";
import testimonials from "../../store/TestimonialData";
import TestimonialCard from "../cards/TestimonialCard";

const Testimonial = () => {
  return (
    <PaperContainer>
      <Stack
        sx={{ my: { xs: 2, sm: 2, md: 10 } }}
        mx={"auto"}
        maxWidth={"xl"}
        height={"100%"}
      >
        <Stack
          py={{ xs: "1.3rem", md: "1rem" }}
          sx={{ bgcolor: "primary.gray700", borderRadius: "16px" }}
        >
          <Carousel animation="slide" duration="500" autoPlay={true}>
            {testimonials.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                heading={testimonial.heading}
                avatar={testimonial.avatar}
                subHeading={testimonial.subHeading}
                name={testimonial.name}
                designation={testimonial.designation}
              />
            ))}
          </Carousel>
        </Stack>
      </Stack>
    </PaperContainer>
  );
};

export default Testimonial;
