import { createTheme } from "@mui/material";

const colors = {
  gray50: "#F9FAFB",
  gray77: "#BFC0C1",
  gray100: "#F2F4F7",
  gray200: "#1f2937",
  gray300: "#D0D5DD",
  gray400: "#98A2B3",
  gray600: "#475467",
  gray700: "#344054",
  gray500: "#667085",
  gray80: "#1D2939",
  blue50: "#F0F9FF",
  gray900: "#101828",
  blue100: "#B9E6FE",
  blue200: "#B9E6FE",
  blue300: "#7CD4FD",
  blue600: "#0086C9",
  blue700: "#026AA2",
  blue800: "#065986",
  blue900: "#0B4A6F",
  sky50: "#f0f9ff",
};

const lightTheme = createTheme({
  palette: {
    // ...(mode === "light" ? "" : ""),
    background: {
      default: "#fff",
      paper: "#fff",
      defaultChannel: "255 255 255",
    },
    primary: {
      main: colors.blue600,
      blue50: colors.blue50,
      blue100: colors.blue100,
      blue200: colors.blue200,
      blue300: colors.blue300,
      blue600: colors.blue600,
      blue700: colors.blue700,
      blue800: colors.blue800,
      blue900: colors.blue900,
      gray50: colors.gray50,
      gray77: colors.gray77,
      gray100: colors.gray100,
      gray200: colors.gray200,
      gray300: colors.gray300,
      gray400: colors.gray400,
      gray600: colors.gray600,
      gray500: colors.gray500,
      gray700: colors.gray700,
      gray800: colors.gray800,
      gray900: colors.gray900,
      white: "#ffffff",
      black: "#000000",
      sky70: colors.sky50,
      //common-black and white,
    },
    grey: {
      main: colors.gray50,
    },
    secondary: {
      main: colors.blue600,
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    button: {
      textTransform: "none",
    },
    displayXlBold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "3rem",
      lineHeight: "3.75rem",
      letterSpacing: "-0.02em",
      // color: colors.gray900,
    },
    displayXlSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "3rem",
      lineHeight: "3.75rem",
      letterSpacing: "-0.02em",
      // color: colors.gray900,
    },
    displaySmSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "30px",
      lineHeight: "38px",
      // letterSpacing: "-0.02em",
      // color: colors.gray900,
    },
    displayLgSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "48px",
      lineHeight: "60px",
      letterSpacing: "-0.02em",
      // color: colors.gray900,
    },
    displayLgBold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "48px",
      lineHeight: "60px",
      letterSpacing: "-0.02em",
      // color: colors.gray900,
    },

    displayXsMedItalics: {
      fontFamily: "Inter",
      fontStyle: "italic",
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "36px",
      // letterSpacing: "-0.02em",
      // color: colors.gray900,
    },
    displayMdSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "36px",
      lineHeight: "44px",
      letterSpacing: "-0.02em",
      // color: colors.gray900,
    },
    displayMdMed: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "30px",
      letterSpacing: "-0.02em",
      //color: colors.gray900,
    },
    displayXsSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "32px",
      letterSpacing: "-0.02em",
      //color: colors.gray900,
    },
    displayXsBold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "28px",
      lineHeight: "38px",
      letterSpacing: "-0.02em",
      //color: colors.gray900,
    },

    textSmSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
    },
    textXlSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "30px",
    },
    textLgSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "28px",
    },

    textMdSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
    },
    textSmMedium: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
    },
    textLgReg: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "28px",
      // color: colors.gray600,
    },
    textXlReg: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "1.25rem",
      lineHeight: "1.875rem",
      // color: colors.gray600,
    },
    textXsReg: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
      // color: colors.gray600,
    },
    textXsMed: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "18px",
      // color: colors.gray600,
    },
    textSmReg: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      // color: colors.gray600,
    },
    textMdReg: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      // color: colors.gray600,
    },
  },
});

const darkTheme = createTheme({
  palette: {
    // ...(mode === "light" ? "" : ""),
    background: {
      default: "#121212",
      paper: "#121212",
      defaultChannel: "18 18 18",
    },
    primary: {
      main: "#121212",
      blue200: colors.blue200,
      blue600: colors.blue600,
      blue700: colors.blue700,
      blue800: colors.blue800,
      gray100: colors.gray100,
      gray300: colors.gray300,
      gray600: colors.gray600,
      gray77: colors.gray77,
      gray700: colors.gray700,
      gray800: colors.gray800,
      gray900: colors.gray900,
      white: "#ffffff",
      black: "#000000",
      //common-black and white,
    },
  },
  shape: {
    borderRadius: "8px",
  },
  typography: {
    button: {
      textTransform: "none",
    },
    displayLgSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "48px",
      lineHeight: "60px",
      letterSpacing: "-0.02em",
      // color: colors.gray900,
    },
    displayMdSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "36px",
      lineHeight: "44px",
      letterSpacing: "-0.02em",
      // color: colors.gray900,
    },
    displayXsSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "32px",
      letterSpacing: "-0.02em",
      //   color: colors.gray900,
    },
    displayMdMed: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "56px",
      lineHeight: "44px",
      letterSpacing: "-0.02em",
      //   color: colors.gray900,
    },

    textSmSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
    },
    textXlSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "30px",
    },
    textMdSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
    },

    textLgReg: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "28px",
      // color: colors.gray600,
    },
    textXlReg: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "30px",
      // color: colors.gray600,
    },
    textSmReg: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      // color: colors.gray600,
    },
  },
});

export { lightTheme, darkTheme };
